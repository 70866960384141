import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom/dist";
import moment from "moment/moment";
import { IoPersonCircleOutline } from "react-icons/io5";

const BlogsBycategory = () => {
  const { blogList } = useSelector((store) => store.Blog);
  const navigate = useNavigate();
  const param = useParams();
  return (
    <>
      <section className="innerPage_blog-area pt-10 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  {param.blogcat} Blogs
                </span>
                <h2 className="section-title mb-0">{param.blogcat} Blogs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {blogList
              .filter((data) => data.category === param.blogcat)
              .map((individualBlog, index) => (
                <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                  <div className="h2_blog-item mb-30">
                    <div
                      className="h2_blog-img"
                      style={{ maxHeight: "235px", minHeight: "235px" }}
                    >
                      <Link to={`/blog-info/${individualBlog.slugUrl}`}>
                        <img src={individualBlog.slider[0]} alt />
                      </Link>
                    </div>
                    <div className="h2_blog-content">
                      <div className="h2_blog-content-meta">
                        <span>
                          <i className="fa-thin fa-user" />
                          Admin
                        </span>
                        <span>
                          <i className="fa-thin fa-clock" />
                          {moment(individualBlog.createdAt).format(
                            "DD MMM YYYY"
                          )}
                        </span>
                      </div>
                      <h5 className="h2_blog-content-title line_elips">
                        <Link to={`/blog-info/${individualBlog.slugUrl}`}>
                          {individualBlog.blogTitle}
                        </Link>
                      </h5>
                      <Link
                        to={`/blog-info/${individualBlog.slugUrl}`}
                        className="theme-btn blog-btn t-theme-btn"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="row">
            <div className="col-12">
              <div className="pagination-area mt-20 mb-30">
                <ul>
                  <li>
                    <Link>01</Link>
                  </li>
                  <li>
                    <Link>02</Link>
                  </li>
                  <li>
                    <Link>03</Link>
                  </li>
                  <li>
                    <Link>04</Link>
                  </li>
                  <li>
                    <Link>
                      <i className="fa-light fa-angle-right" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default BlogsBycategory;
