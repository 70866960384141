import React from "react";
import { useSelector } from "react-redux";
import Dashbord from "./components/Dashbord";
import SearchPrograms from "./components/SearchPrograms";
import MatchUniversities from "./components/MatchUniversities";
import DraftSOP from "./components/DraftSOP";
import DraftRecommendation from "./components/DraftRecommendation";
import ServiesRequiest from "./components/ServiesRequiest";
import TestPrep from "./components/TestPrep";
import ApplayDirect from "./components/ApplayDirect";
import RenewSubscription from "./components/RenewSubscription";
import PendingActions from "./components/PendingActions";
import ViewDetails from "./components/ViewDetails";
import MatchedPrograms from "../MatchedPrograms";
import UniversityByProfile from "../UniversityByProfile";
import UniversityByObject from "../UniversityByObject";
import ProgramDetails from "../ProgramDetails";
import UniversityInfoPage from "../universityInfo/UniversityInfoPage";

const DashBordComponents = () => {
  const { dashboardPageStatus } = useSelector(
    (store) => store.StudentDashboard
  );

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <>
            <Dashbord />
          </>
        );
      case 1:
        return (
          <>
            <SearchPrograms />
          </>
        );
      case 2:
        return (
          <>
            <UniversityByProfile />
          </>
        );
      case 3:
        return (
          <>
            <DraftSOP />
          </>
        );
      case 4:
        return (
          <>
            <DraftRecommendation />
          </>
        );
      case 5:
        return (
          <>
            <ServiesRequiest />
          </>
        );
      case 6:
        return (
          <>
            <TestPrep />
          </>
        );

      case 7:
        return (
          <>
            <ApplayDirect />
          </>
        );
      case 8:
        return (
          <>
            <PendingActions />
          </>
        );
      case 9:
        return (
          <>
            <RenewSubscription />
          </>
        );
      case 10:
        return (
          <>
            <ViewDetails />
          </>
        );
      case 11:
        return (
          <>
            <MatchedPrograms />
          </>
        );
      case 12:
        return (
          <>
            <UniversityByProfile />
          </>
        );
      case 13:
        return (
          <>
            <UniversityByObject />
          </>
        );
      case 14:
        return (
          <>
            <ProgramDetails />
          </>
        );
      case 15:
        return (
          <>
            <UniversityInfoPage />
          </>
        );

      default:
        return (
          <>
            <Dashbord />
          </>
        );
    }
  };

  return <>{renderSwitch(dashboardPageStatus)}</>;
};

export default DashBordComponents;
