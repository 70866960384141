import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";

import {
  Button,
  Modal,
  Space,
  Flex,
  Form,
  Select,
  Col,
  Divider,
  Row,
  Typography,
  Radio,
} from "antd";
const { Option } = Select;
const { Title } = Typography;

const SelectDegree = ({ changeSign_up_status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [degreeName, setDegreeName] = useState("");
  const [degreeNameError, setDegreeNameError] = useState("");
  const [question, setQuestion] = useState("");
  const [questionResponse, setQuestionResponse] = useState(true);
  const [showInstructions, setShowInstructions] = useState("Instructions");
  const [showInstructions2, setShowInstructions2] = useState("");
  const [btnPress, setbtnPress] = useState(false);

  const [info_model, setinfo_model] = useState(false);

  useEffect(() => {
    if (btnPress === true) {
      if (degreeName === "") {
        setDegreeNameError("Required");
      } else {
        setDegreeNameError("");
      }
    }
  }, [btnPress, degreeName]);

  const selectDegreeChange = (e) => {
    const value = e;
    setDegreeName(value);
    if (value === "Undergraduate") {
      setQuestion(
        "Does your  educational background meet the equivalent of a High School Diploma"
      );
      setShowInstructions(
        `In the USA, Bachelor's programs require applicants to have completed their secondary education, which means obtaining a high school diploma or an equivalent following 12 years of schooling.`
      );
      setShowInstructions2(
        " Also, please note that there are a few colleges or universities offering transfer or lateral admission into their 2-year Bachelor's program if the applicant has completed courses equivalent to an Associate Degree (2 years) in the USA. This requires an educational evaluation of your completed post-secondary courses. It's a good opportunity to join a 2-year Bachelor's degree program."
      );
    } else {
      setQuestion("Do you have a qualification equivalent to a 4 year degree");
      setShowInstructions2("");
      setShowInstructions(
        "Your degree should be equivalent to a 4-year undergraduate program in the United States. Students from countries where 3-year degrees are awarded can complete a 1-year study of any degree or diploma program to achieve the 4-year equivalency."
      );
    }
  };

  const SelectDegree = [
    {
      id: 1,
      name: "Undergraduate",
      showname: "Undergraduate (Bachelors)",
    },
    {
      id: 2,
      name: "Graduate",
      showname: "Graduate (Masters)",
    },
    {
      id: 3,
      name: "Doctoral",
      showname: "Doctoral (Ph.D.)",
    },
  ];

  const warning = () => {
    Modal.warning({
      title: "This is a warning message",
      content:
        " We appreciate your interest. Currently, it seems you are NOT ELIGIBLE to avail our services. Feel free to revisit whenever you genuinely require the services of F1VISA.ONLINE in the future. Thank you",
      onOk() {
        navigate("/");
      },
    });
  };
  const warning_info = () => {
    Modal.info({
      title: "This is a warning message",
      content:
        " We appreciate your interest. Currently, it seems you are NOT ELIGIBLE to avail our services. Feel free to revisit whenever you genuinely require the services of F1VISA.ONLINE in the future. Thank you",
    });
  };

  const continueClickk = (e) => {
    setbtnPress(true);

    if (degreeName === "") {
      setDegreeNameError("Required");
    } else {
      setDegreeNameError("");
    }

    if (degreeName !== "") {
      if (questionResponse === true) {
        changeSign_up_status(2, degreeName, questionResponse);
      } else {
        warning();
      }
    }
  };

  return (
    <>
      <Modal
        title=""
        open={info_model}
        // onOk={() => setinfo_model(false)}
        onCancel={() => setinfo_model(false)}
        footer={null}
        maskClosable={false}
      >
        <p
          className="mt-4"
          style={{
            textAlign: "justify",
          }}
        >
          {showInstructions}
        </p>
        {showInstructions2 !== "" && (
          <p
            style={{
              textAlign: "justify",
            }}
          >
            {showInstructions2}
          </p>
        )}
      </Modal>
      <div className="account-area pt-10 pb-10 backgroundimage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 pt-50">
              <div className="account-wrap">
                <div className="account-main">
                  <div className="sing_image">
                    <Link to="/">
                      <img src="assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  {/* <h3 className="account-title">Sign Up to Your Account</h3> */}
                  <div
                    style={{
                      marginTop: "5vh",
                    }}
                  ></div>
                  <span className="account-form">
                    <div className="account-form-item mb-10">
                      <Col className="gutter-row">
                        <Title level={5}>
                          Choose a program level you wish to apply for
                        </Title>
                        <Form.Item
                          hasFeedback
                          validateStatus={degreeNameError !== "" ? "error" : ""}
                          style={{ width: "100%" }}
                          name="layout"
                          className="ant_custo"
                        >
                          <Select
                            placeholder="Choose program level"
                            value={degreeName}
                            size="large"
                            onChange={(value) => {
                              selectDegreeChange(value);
                            }}
                            className="ant_drop_down"
                          >
                            {SelectDegree.map((data, index) => (
                              <Option
                                key={index}
                                value={data.name}
                                name={data.name}
                                selected={degreeName === data.name}
                              >
                                {data.showname}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                    {question !== "" && (
                      <>
                        <div className="account-form-item mb-15">
                          <Col className="gutter-row">
                            <Title level={5}>
                              {question}
                              <FaInfoCircle
                                className="mx-2 currsor_po"
                                onClick={() => setinfo_model(true)}
                              />
                            </Title>
                          </Col>
                        </div>

                        <div className="account-form-condition set_remember_inputts">
                          <Radio.Group defaultValue={true}>
                            <Radio
                              value={true}
                              checked={questionResponse === true}
                              onClick={() => setQuestionResponse(true)}
                            >
                              Yes
                            </Radio>
                            <Radio
                              value={false}
                              checked={questionResponse === false}
                              onClick={() => setQuestionResponse(false)}
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        </div>
                      </>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                      className="account-form-button d-flex justify-content-center"
                    >
                      <button
                        type="submit"
                        className="account-btn"
                        onClick={(e) => continueClickk(e)}
                      >
                        Continue
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReactTooltip
          id="my-tooltip-1"
          place="top"
          variant="info"
          content={showInstructions}
          className="tooltip_style"
        />
      </div>
    </>
  );
};

export default SelectDegree;
