import React from "react";

const EducationalCounslingMode = () => {
  return (
    <>
      <section className="h9_choose-area pt-40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-20">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#f14d5d",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  Why Choose Us
                </span>
                <h2 className="section-title mb-0">
                  Study Abroad Educational Counseling made{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      // textDecorationLine: "underline",
                      color: "#f14d5d",
                    }}
                  >
                    Affordable{" "}
                  </span>
                  and{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      // textDecorationLine: "underline",
                      color: "#f14d5d",
                    }}
                  >
                    Scientific
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="h9_choose-item mb-30">
                <div className="h9_choose-item-icon">
                  <img src="/assets/img/choose/1.png" alt="" />
                </div>
                <h4
                  className="h9_choose-item-title"
                  style={{
                    color: "#f14d5d",
                  }}
                >
                  Complete Transparency
                </h4>
                <p>
                  Powered by cutting-edge technology, this platform ensures
                  complete transparency in all transactions.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="h9_choose-item mb-30">
                <div className="h9_choose-item-icon">
                  <img src="/assets/img/choose/2.png" alt="" />
                </div>
                <h4
                  className="h9_choose-item-title"
                  style={{
                    color: "#f14d5d",
                  }}
                >
                  Absolute Safety
                </h4>
                <p>
                  Our platform guarantees 100% safety by exclusively featuring
                  properly accredited institutions.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="h9_choose-item mb-30">
                <div className="h9_choose-item-icon">
                  <img src="/assets/img/choose/3.png" alt="" />
                </div>
                <h4
                  className="h9_choose-item-title"
                  style={{
                    color: "#f14d5d",
                  }}
                >
                  High Success
                </h4>
                <p>
                  Finding university programs that align perfectly with your
                  education and goals increases your chances of success, leading
                  to positive admission decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EducationalCounslingMode;
