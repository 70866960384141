import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import Header from "./Header";
import { Typography, Radio, Col, Button, Space } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import moment from "moment/moment";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setAdminssionIntent,
} from "../../../redux/dashboard/PageRenderSlice";

const AdmissionSession = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData } = useSelector((store) => store.Athentication);
  const { studentDetail } = useSelector((store) => store.StudentRegd);

  const [programYear, setProgramyear] = useState("");
  const [programSession, setprogramSession] = useState("");
  const [ProgramYearArray, setProgramYearArray] = useState([]);
  const [allsessionArray, setallsessionArray] = useState([]);
  const [allsessionArrayShow, setallsessionArrayShow] = useState([]);
  const [currentDate, setCurrentDate] = useState("");

  // Error Message
  const [programYearError, setProgramyearError] = useState("");
  const [programSessionError, setprogramSessionError] = useState("");

  useEffect(() => {
    if (studentDetail && studentDetail.currentFormStep >= 3) {
      setProgramyear(studentDetail.admissionSession.programYear);
      setprogramSession(studentDetail.admissionSession.programSession);
    }
  }, [studentDetail]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    let day = new Date();
    setCurrentDate(day);
    const programYears = [];

    for (let i = 0; i < 3; i++) {
      programYears.push({
        id: i + 1,
        name: (currentYear + i).toString(),
      });
    }
    setProgramYearArray([...programYears]);
    let allsession = [];
    for (let j = 0; j < programYears.length; j++) {
      let sessionarray = [
        {
          id: 1,
          name: "Winter",
          Month: 1,
          Year: programYears[j].name,
        },
        {
          id: 2,
          name: "Spring",
          Month: 4,
          Year: programYears[j].name,
        },
        {
          id: 3,
          name: "Summer",
          Month: 7,
          Year: programYears[j].name,
        },
        {
          id: 4,

          name: "Fall",
          Month: 10,
          Year: programYears[j].name,
        },
      ];
      allsession = [...allsession, ...sessionarray];
    }
    allsession = [...allsession];
    allsession = [...allsession];

    setallsessionArray(allsession);
  }, []);

  useEffect(() => {
    if (programYear !== "") {
      let day = new Date();
      let currentYear = new Date().getFullYear();
      let selectedyearSeassions = allsessionArray.filter(
        (data) => data.Year === programYear
      );

      if (currentYear.toString() === programYear) {
        if (
          moment(day).format("M").toString() >= "1" &&
          moment(day).format("M").toString() <= "3"
        ) {
          selectedyearSeassions = selectedyearSeassions.filter(
            (data) => data.Month !== 1
          );
          setallsessionArrayShow(selectedyearSeassions);
        } else if (
          moment(day).format("M").toString() >= "4" &&
          moment(day).format("M").toString() <= "6"
        ) {
          selectedyearSeassions = selectedyearSeassions.filter(
            (data) => data.Month !== 4 && data.Month !== 1
          );
          setallsessionArrayShow(selectedyearSeassions);
        } else if (
          moment(day).format("M").toString() >= "7" &&
          moment(day).format("M").toString() <= "9"
        ) {
          selectedyearSeassions = selectedyearSeassions.filter(
            (data) => data.Month !== 7 && data.Month !== 4 && data.Month !== 1
          );
          setallsessionArrayShow(selectedyearSeassions);
        } else if (
          moment(day).format("M").toString() >= "10" &&
          moment(day).format("M").toString() >= "12"
        ) {
          selectedyearSeassions = selectedyearSeassions.filter(
            (data) => data.Month === 10
          );
          setallsessionArrayShow(selectedyearSeassions);

          const filterYear = ProgramYearArray.filter(
            (data) => data.name !== currentYear.toString()
          );
          setProgramYearArray(filterYear);
        }
      } else {
        setallsessionArrayShow(selectedyearSeassions);
      }
    }
  }, [programYear, allsessionArray, ProgramYearArray]);

  const [age, setAge] = useState("");

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const AdmissionIntentClick = async () => {
    if (studentDetail.currentFormStep > 3) {
      dispatch(dashPageStatuseChange(4));
    } else {
      try {
        if (programYear !== "" && programSession !== "") {
          const formData = {
            _id: loginData._id,
            admissionSession: {
              programYear: programYear,
              programSession: programSession,
            },
            currentFormStep: 4,
            admissionSessionStatus: true,
          };

          const student = await dispatch(updateStudent(formData));
          if (student.payload.success) {
            dispatch(setAdminssionIntent(formData));
            dispatch(dashPageStatuseChange(4));
          }
        } else {
          if (programYear === "") {
            setProgramyearError("Required");
          } else {
            setProgramyearError("");
          }
          if (programSession === "") {
            setprogramSessionError("Required");
          } else {
            setprogramSessionError("");
          }
        }
      } catch (error) {}
    }
  };
  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Admission Session"
            description="Please let us know the session for which you plan to apply for admission"
          />
          <div className="inputs_container">
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Program year"
                select
                className="input_style"
                error={programYearError !== "" ? true : false}
                helperText={programYearError}
                value={programYear}
                onChange={(e) => setProgramyear(e.target.value)}
                size="small"
              >
                {ProgramYearArray.map((program, index) => (
                  <MenuItem key={index} value={program.name}>
                    {program.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Program session"
                select
                className="input_style"
                error={programSessionError !== "" ? true : false}
                helperText={programSessionError}
                value={programSession}
                onChange={(e) => setprogramSession(e.target.value)}
                size="small"
              >
                {allsessionArrayShow.map((program, index) => (
                  <MenuItem key={index} value={program.name}>
                    {program.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="button_container">
              <Button
                type="primary"
                loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={() => AdmissionIntentClick()}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionSession;
