import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import {
  Typography,
  Radio,
  Col,
  Button,
  Space,
  Table,
  Modal,
  message,
} from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import moment from "moment/moment";
import Header from "../../student_registation/components/Header";
import { testSchedulePost } from "../../../redux/Test/TestSlice";
const TestPrep = () => {
  const { totalTest } = useSelector((store) => store.testManagement);
  const { loginData } = useSelector((store) => store.Athentication);
  const [selectedTest, setselectedTest] = useState("");
  const [selecteddate, setselecteddate] = useState("");
  const [selectedTime, setselectedTime] = useState("");
  const [selectedTimeVisible, setselectedTimeVisible] = useState("");
  const [modal, setModal] = useState(false);
  const today = new Date();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggle = () => setModal(!modal);
  const [messageApi, contextHolder] = message.useMessage();

  const timeings = [
    { hour: 0, name: "12 AM" },
    { hour: 1, name: "01 AM" },
    { hour: 2, name: "02 AM" },
    { hour: 3, name: "03 AM" },
    { hour: 4, name: "04 AM" },
    { hour: 5, name: "05 AM" },
    { hour: 6, name: "06 AM" },
    { hour: 7, name: "07 AM" },
    { hour: 8, name: "08 AM" },
    { hour: 9, name: "09 AM" },
    { hour: 10, name: "10 AM" },
    { hour: 11, name: "11 AM" },
    { hour: 12, name: "12 PM" },
    { hour: 13, name: "01 PM" },
    { hour: 14, name: "02 PM" },
    { hour: 15, name: "03 PM" },
    { hour: 16, name: "04 PM" },
    { hour: 17, name: "05 PM" },
    { hour: 18, name: "06 PM" },
    { hour: 19, name: "07 PM" },
    { hour: 20, name: "08 PM" },
    { hour: 21, name: "09 PM" },
    { hour: 22, name: "10 PM" },
    { hour: 23, name: "11 PM" },
  ];

  const handleExam = async (testdata) => {
    setselectedTest(testdata);
    setModal(!modal);
  };
  const cancelClick = async () => {
    setModal(false);
    setselecteddate("");
    setselectedTime("");
    setselectedTimeVisible("");
  };

  const selectTimeChange = (evt) => {
    const findTime = timeings.find((data) => data.name === evt);
    setselectedTime(findTime.hour);
    setselectedTimeVisible(findTime.name);
  };
  const handlesubmit = async () => {
    if (selectedTimeVisible !== "" && selecteddate !== "") {
      const selectedDateString = moment(selecteddate).format("DD-MM-YYYY");
      const formdata = {
        studentId: loginData._id,
        testId: selectedTest._id,
        testTitle: selectedTest.TestTitle,
        testCode: selectedTest.TestCode,
        examType: selectedTest.examType,
        scheduleDate: selectedDateString,
        scheduleTime: selectedTime,
        scheduleTimeVisible: selectedTimeVisible,
      };
      const data = await dispatch(testSchedulePost(formdata));
      if (data.payload.success) {
        messageApi.open({
          type: "success",
          content: "Test schedule successfully",
        });
        setModal(false);
        setselecteddate("");
        setselectedTime("");
        setselectedTimeVisible("");
      }
    }
  };

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "index",
    //   key: "No",
    //   render: (text) => <span>{text}</span>,
    //   width: 70,
    // },
    {
      title: "Title",
      dataIndex: "TestTitle",
      key: "Title",
      render: (text) => <Link>{text}</Link>,
      width: 200,
    },

    {
      title: "Test Code",
      dataIndex: "TestCode",
      key: "Test Code",
      render: (text) => <Link>{text}</Link>,
      width: 100,
    },

    {
      title: " Test Type",
      dataIndex: "TestType",
      key: " Test Type",
      render: (text) => <Link>{text}</Link>,
      width: 100,
    },
    {
      title: "Questions",
      dataIndex: "NumberofQuestions",
      key: "Questions",
      render: (text) => <Link>{text}</Link>,
      width: 100,
    },
    {
      title: "Duration",
      dataIndex: "Duration",
      key: "Duration",
      render: (text) => <Link>{text}</Link>,
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "Test CodeName",
      key: "Action",
      width: 100,

      render: (text, record) => (
        <span style={{ cursor: "pointer" }} onClick={() => handleExam(record)}>
          Schedule Test
        </span>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Modal
        open={modal}
        title="Select test schedule timings"
        onOk={(e) => handlesubmit(e)}
        onCancel={() => cancelClick()}
        okText="Schedule"
        closable={false}
      >
        <div className="Model_container">
          <div className="dn_input_box">
            <input
              type="date"
              value={selecteddate}
              onChange={(e) => setselecteddate(e.target.value)}
              className="input_style"
            />
          </div>
          <div className="dn_input_box mt-3">
            <TextField
              id="select"
              label="Select Time"
              select
              className="input_style"
              // error={countryofbirthError !== "" ? true : false}
              // helperText={countryofbirthError}
              onChange={(e) => selectTimeChange(e.target.value)}
              value={selectedTimeVisible}
              size="small"
            >
              {timeings.map((country, index) => (
                <MenuItem key={index} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </Modal>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Prepare for your English and other Required Tests"
            description="We have developed a variety of preparatory tests to ensure your success."
          />
          <div className="inputs_container">
            <div className="radio_div_container">
              <span className="radio_title">
                The assessment aims to evaluate your proficiency in various
                aspects of the English language, including reading
                comprehension, grammar, vocabulary, and writing skills. Please
                read each question carefully and provide your responses
                thoughtfully before you start the exam. Make sure to manage your
                time effectively, allocating sufficient time to each section.
                Remember to review your answers before submitting your exam.
                Good luck!
              </span>
            </div>
          </div>
          <div className="inputs_container">
            <Table
              columns={columns}
              dataSource={totalTest}
              virtual
              scroll={{
                y: 400,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TestPrep;
