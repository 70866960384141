import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { Space, Table, Tag, Affix, Button, Modal } from "antd";
import { FaHeart } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

import {
  deleteWishlist,
  postWishList,
  setallUniversityIds,
} from "../redux/Test/TestSlice";
import ProgramDetailsModel from "./program/ProgramDetailsModel";
import { studentDashboardPageChange } from "../redux/studentDashboardPage/StudentDasboardSlice";
import {
  setSelectProgram,
  setSelectUniversity,
} from "../redux/authentication/AuthenticationSlice";

const MatchedPrograms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData } = useSelector((store) => store.Athentication);
  const {
    programSearchObjects,
    wishListTotal,
    wishListTotalUniIds,
    totalPrograms,
    totalUniversity,
  } = useSelector((store) => store.testManagement);

  const [temploading, setTempLoading] = useState(true);
  const [fetchedProgram, setfetchedProgram] = useState([]);
  const [selectedProgram, setselectedProgram] = useState("");
  const [currentShoingData, setcurrentShoingData] = useState([]);
  const [noOfShowdata, setnoOfShowdata] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPage, setnoOfPage] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (programSearchObjects !== "" && totalPrograms.length > 0) {
      let eduLeve = "";
      if (loginData.degreeName === "Undergraduate") {
        eduLeve = "Bachelor's Degree";
      } else if (loginData.degreeName === "Graduate") {
        eduLeve = "Master's Degree";
      } else if (loginData.degreeName === "Doctoral") {
        eduLeve = "Doctoral Degree";
      }
      let filter_data = [];
      if (programSearchObjects.selectedUniversity.length > 0) {
        const get_program_university = totalPrograms.filter((element) =>
          programSearchObjects.selectedUniversity.includes(
            element.unversityName
          )
        );
        filter_data = [...filter_data, ...get_program_university];
      }
      if (programSearchObjects.program_selected.length > 0) {
        let fdata = [];
        if (filter_data.length > 0) {
          for (
            let i = 0;
            i < programSearchObjects.program_selected.length;
            i++
          ) {
            let element = programSearchObjects.program_selected[i];

            let code = String(element.code);
            let beforeDot = code.split(".")[0];
            if (beforeDot.length === 1) {
              const values = `0${beforeDot}`;

              const filter_programs = filter_data.filter(
                (data) => String(data.CIPCODE).substring(0, 2) === values
              );
              fdata = [...fdata, ...filter_programs];
            } else {
              const filter_programs = filter_data.filter(
                (data) =>
                  String(data.CIPCODE).substring(0, 2) === beforeDot &&
                  data.educationLevel === eduLeve
              );
              fdata = [...fdata, ...filter_programs];
            }
          }
        } else {
          for (
            let i = 0;
            i < programSearchObjects.program_selected.length;
            i++
          ) {
            let element = programSearchObjects.program_selected[i];

            let code = String(element.code);
            let beforeDot = code.split(".")[0];
            if (beforeDot.length === 1) {
              const values = `0${beforeDot}`;

              const filter_programs = totalPrograms.filter(
                (data) =>
                  String(data.CIPCODE).substring(0, 2) === values &&
                  data.educationLevel === eduLeve
              );
              fdata = [...fdata, ...filter_programs];
            } else {
              const filter_programs = totalPrograms.filter(
                (data) =>
                  String(data.CIPCODE).substring(0, 2) === beforeDot &&
                  data.educationLevel === eduLeve
              );
              fdata = [...fdata, ...filter_programs];
            }
          }
        }
        filter_data = [...fdata];
      }

      if (programSearchObjects.selectedUniversityType.length > 0) {
        if (filter_data.length > 0) {
          const get_program_university_type = filter_data.filter((element) =>
            programSearchObjects.selectedUniversityType.includes(
              element.universityType
            )
          );
          filter_data = [...get_program_university_type];
        } else {
          const get_program_university_type = totalPrograms.filter((element) =>
            programSearchObjects.selectedUniversityType.includes(
              element.universityType
            )
          );
          filter_data = [...get_program_university_type];
        }
      }
      if (programSearchObjects.states_selected.length > 0) {
        if (filter_data.length > 0) {
          const get_program_university_state = filter_data.filter((element) =>
            programSearchObjects.states_selected.includes(element.state)
          );
          filter_data = [...get_program_university_state];
        } else {
          const get_program_university_state = totalPrograms.filter((element) =>
            programSearchObjects.states_selected.includes(element.state)
          );
          filter_data = [...get_program_university_state];
        }
      }
      const uniTotal = filter_data.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setcurrentShoingData([...uniTotal]);
      setTempLoading(false);
    } else {
      setTempLoading(false);
    }
  }, [programSearchObjects, totalPrograms, totalUniversity]);

  const postWishListPress = (UNITID) => {
    const university = wishListTotal.find((data) => data.UNITID === UNITID);
    const formdata = {
      studenId: loginData._id,
      ...university,
    };
    dispatch(postWishList(formdata));
    dispatch(setallUniversityIds());
  };
  const deleteWishListPress = (UNITID) => {
    const university = wishListTotal.find((data) => data.UNITID === UNITID);
    dispatch(deleteWishlist(university._id));
    dispatch(setallUniversityIds());
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "No",
      render: (text) => <span>{text}</span>,
      width: 60,
    },
    {
      title: "Institution",
      dataIndex: "unversityName",
      key: "Institution",
      render: (text, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(studentDashboardPageChange(15));
            dispatch(setSelectUniversity(record));
          }}
        >
          {text}
        </span>
      ),
      width: 290,
    },
    {
      title: "Program",
      dataIndex: "programName",
      key: "Program",
      width: 290,

      render: (text, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setselectedProgram(record);
            setOpen(true);
          }}
        >
          {record.programName}
        </span>
      ),
    },
    {
      title: "View Details",
      key: "action",
      width: 110,
      render: (_, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(studentDashboardPageChange(14));
              dispatch(setSelectProgram(record));
            }}
          >
            <span>View More</span>
          </span>
        </>
      ),
    },
  ];

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 1000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box max_height_cont background_style">
          {temploading ? (
            <>
              <div
                style={{
                  //   width: "100%",
                  //   height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h6>Loading...</h6>
              </div>
            </>
          ) : (
            <>
              {/* <Affix offsetTop={100}> */}
              <Table
                columns={columns}
                dataSource={currentShoingData}
                virtual
                scroll={{
                  y: 350,
                }}
                //   onRow={(record, rowIndex) => {
                //     return {
                //       onClick: (event) => {
                //         console.log(event, "event");
                //       },
                //     };
                //   }}
              />
              {/* </Affix> */}
            </>
          )}
        </div>

        <Modal
          open={open}
          //   title="Title"
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          width={800}
          style={{
            top: 20,
          }}
          footer={[
            <Button key="back" className="btttttttnnn" onClick={handleCancel}>
              Back
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="btnnnnnnbtn"
              loading={loading}
              onClick={handleOk}
            >
              Apply Now
            </Button>,
          ]}
        >
          <ProgramDetailsModel selectedProgram={selectedProgram} />
        </Modal>
      </div>
    </>
  );
};

export default MatchedPrograms;
