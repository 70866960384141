import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
const initialState = {
  blogList: [],
  isBlogLoading: true,
};
export const getBlogList = createAsyncThunk(
  "blog/getBlog",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/blog/all`;
      const resp = await axios(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Blog Not Found");
    }
  }
);
export const updateBlog = createAsyncThunk(
  "blog/updateBlog",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/blog/updateblog/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Blog not able to update");
    }
  }
);
const BlogSlice = createSlice({
  name: "Blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogList.pending, (state) => {
        state.isBlogLoading = true;
      })
      .addCase(getBlogList.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.blogList = action.payload.blogs;
        }
        state.isBlogLoading = false;
      })
      .addCase(getBlogList.rejected, (state) => {
        state.isBlogLoading = true;
      })
      .addCase(updateBlog.pending, (state) => {
        state.isBlogLoading = true;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.blogList = state.blogList.filter(
            (blog) => blog._id !== action.payload.blog._id
          );
          state.blogList = [action.payload.blog, ...state.blogList];
        }
        localStorage.setItem("totalBlogs", JSON.stringify(state.blogList));
        state.isBlogLoading = false;
      })
      .addCase(updateBlog.rejected, (state) => {
        state.isBlogLoading = true;
      });
  },
});
export default BlogSlice.reducer;
