import { configureStore } from "@reduxjs/toolkit";
import StudentRegdReducer from "../redux/StudentRegd/StudentRegdSlice";
import DashboardPageReducer from "../redux/dashboard/PageRenderSlice";
import AthenticationReducer from "../redux/authentication/AuthenticationSlice";
import BlogReducer from "../redux/blog/BlogSlice";
import StudentDashboardReducer from "../redux/studentDashboardPage/StudentDasboardSlice";
import TestReducer from "../redux/Test/TestSlice";
export const store = configureStore({
  reducer: {
    StudentRegd: StudentRegdReducer,
    DashboardPage: DashboardPageReducer,
    StudentDashboard: StudentDashboardReducer,
    Athentication: AthenticationReducer,
    Blog: BlogReducer,
    testManagement: TestReducer
  },
});
