import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { act } from "react-dom/test-utils";
import { compress, decompress } from 'lz-string';


const initialState = {
    totalTest: [],
    TestLoading: true,
    totalTestSchedule: [],
    TestLoadingSchedule: true,
    // totalUniversity: localStorage.getItem("totalUniversity")
    //     ? JSON.parse(localStorage.getItem("totalUniversity"))
    //     : [],
    totalUniversity: localStorage.getItem("totalUniversity")
        ? JSON.parse(decompress(localStorage.getItem("totalUniversity")))
        : [],
    isUniversityAvailable: localStorage.getItem("totalUniversity")
        ? true
        : false,
    universityLoading: true,

    totalPrograms: localStorage.getItem("totalPrograms")
        ? JSON.parse(decompress(localStorage.getItem("totalPrograms")))
        : [],
    isProgramAvailable: localStorage.getItem("totalPrograms")
        ? true
        : false,
    programLoading: true,

    major_subjects: localStorage.getItem("major_subjects")
        ? JSON.parse(decompress(localStorage.getItem("major_subjects")))
        : [],
    isMajor_subjectsAvailable: localStorage.getItem("major_subjects")
        ? true
        : false,
    major_subjectsLoading: true,

    programSearchObjects: localStorage.getItem("programSearchObjects")
        ? JSON.parse(localStorage.getItem("programSearchObjects"))
        : "",
    universitySearchObjects: localStorage.getItem("universitySearchObjects")
        ? JSON.parse(localStorage.getItem("universitySearchObjects"))
        : "",
    universitySearchObjectsResutls: localStorage.getItem("universitySearchObjectsResutls")
        ? JSON.parse(localStorage.getItem("universitySearchObjectsResutls"))
        : [],

    wishListTotal: [],
    wishListTotalUniIds: [],
    wishlistLoading: true,
};

export const postWishList = createAsyncThunk(
    "test/postWishList",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/wishlist/new`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("Blog not able to upload");
        }
    }
);

export const getAllWishlist = createAsyncThunk(
    "test/getAllWishlist",
    async (id, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/wishlist/last-ten-wishlist/${id}`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)

export const deleteWishlist = createAsyncThunk(
    "test/deleteWishlist",
    async (individualBlog, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/wishlist/delete-wishlist/${individualBlog}`;
            const resp = await axios.delete(url);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("Blog not able to delete");
        }
    }
);

export const getAllUniversity = createAsyncThunk(
    "test/getAllUniversity",
    async (formData, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/university/all`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)
export const getsingleUniversity = createAsyncThunk(
    "test/getsingleUniversity",
    async (id, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/university/university-UNITID/${id}`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)
export const getAllMajor_subjects = createAsyncThunk(
    "test/getAllMajor_subjects",
    async (formData, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/majorsubjects/all`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)
export const getAllPrograms = createAsyncThunk(
    "test/getAllPrograms",
    async (formData, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/program/all`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)
export const getTestByDegreeName = createAsyncThunk(
    "test/getTestByDegreeName",
    async (formData, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/testmanagement/all-test-management/${formData.degreeName}`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)
export const getTestScheduleByStudentId = createAsyncThunk(
    "testManagement/getTestScheduleByStudentId",
    async (formData, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/testschedule/testschedulebystudentId/${formData._id}`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)

export const testSchedulePost = createAsyncThunk(
    "testManagement/testSchedulepost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/testschedule/new`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("Blog not able to upload");
        }
    }
);

const testManagementSlice = createSlice({
    name: "testManagement",
    initialState,
    reducers: {
        setprogramSearchObjects(state, action) {
            state.programSearchObjects = action.payload;
            localStorage.setItem("programSearchObjects", JSON.stringify(state.programSearchObjects));
        },
        setUniversitySearchObjects(state, action) {
            state.universitySearchObjects = action.payload;
            localStorage.setItem("universitySearchObjects", JSON.stringify(state.universitySearchObjects));
        },
        setUniversitySearchObjectsResult(state, action) {
            state.universitySearchObjectsResutls = action.payload;
            localStorage.setItem("universitySearchObjectsResutls", JSON.stringify(state.universitySearchObjectsResutls));
        },
        setallUniversityIds(state, action) {
            if (state.wishListTotal.length > 0) {
                let allUniversityIds = [];
                for (let index = 0; index < state.wishListTotal.length; index++) {
                    let element = state.wishListTotal[index];
                    allUniversityIds = [...allUniversityIds, element.UNITID];
                }
                allUniversityIds = [...allUniversityIds]
                state.wishListTotalUniIds = [...allUniversityIds]
            }
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getTestByDegreeName.pending, (state) => {
                state.TestLoading = true;
            })
            .addCase(getTestByDegreeName.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalTest = action.payload.testManagement
                }
                state.TestLoading = false;
            })
            .addCase(getTestByDegreeName.rejected, (state) => {
                state.TestLoading = true;
            })
            .addCase(getAllUniversity.pending, (state) => {
                state.universityLoading = true;
            })
            .addCase(getAllUniversity.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalUniversity = action.payload.university.sort((a, b) => (a.universityName > b.universityName ? 1 : -1))
                    localStorage.setItem("totalUniversity", compress(JSON.stringify(state.totalUniversity)));
                }
                state.universityLoading = false;
                state.isUniversityAvailable = true;
            })
            .addCase(getAllUniversity.rejected, (state) => {
                state.universityLoading = true;
            })
            .addCase(getAllPrograms.pending, (state) => {
                state.programLoading = true;
            })
            .addCase(getAllPrograms.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalPrograms = action.payload.programs
                    localStorage.setItem("totalPrograms", compress(JSON.stringify(state.totalPrograms)));
                }
                state.programLoading = false;
                state.isProgramAvailable = true;
            })
            .addCase(getAllPrograms.rejected, (state) => {
                state.programLoading = true;
            })
            .addCase(getAllMajor_subjects.pending, (state) => {
                state.major_subjectsLoading = true;
            })
            .addCase(getAllMajor_subjects.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.major_subjects = action.payload.majorSubject
                    localStorage.setItem("major_subjects", compress(JSON.stringify(state.major_subjects)));
                }
                state.isMajor_subjectsAvailable = false;
                state.major_subjectsLoading = true;
            })
            .addCase(getAllMajor_subjects.rejected, (state) => {
                state.major_subjectsLoading = true;
            })
            .addCase(testSchedulePost.pending, (state) => {
                state.TestLoadingSchedule = true;
            })
            .addCase(testSchedulePost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalTestSchedule = [action.payload.testSchedule];
                }
                state.TestLoadingSchedule = false;
            })
            .addCase(testSchedulePost.rejected, (state) => {
                state.TestLoadingSchedule = true;
            })
            .addCase(getTestScheduleByStudentId.pending, (state) => {
                state.TestLoadingSchedule = true;
            })
            .addCase(getTestScheduleByStudentId.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalTestSchedule = action.payload.testSchedule
                }
                state.TestLoadingSchedule = false;
            })
            .addCase(getTestScheduleByStudentId.rejected, (state) => {
                state.TestLoadingSchedule = true;
            })

            .addCase(postWishList.pending, (state) => {
                state.wishlistLoading = true;
            })
            .addCase(postWishList.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.wishListTotal = [action.payload.wishList, ...state.wishListTotal];
                    if (state.wishListTotal.length > 0) {
                        let allUniversityIds = [];
                        for (let index = 0; index < state.wishListTotal.length; index++) {
                            let element = state.wishListTotal[index];
                            allUniversityIds = [...allUniversityIds, element.UNITID];
                        }
                        allUniversityIds = [...allUniversityIds]
                        state.wishListTotalUniIds = [...allUniversityIds]
                    }
                }
                state.wishlistLoading = false;
            })
            .addCase(postWishList.rejected, (state) => {
                state.wishlistLoading = true;
            })

            .addCase(getAllWishlist.pending, (state) => {
                state.wishlistLoading = true;
            })
            .addCase(getAllWishlist.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.wishListTotal = action.payload.wishList
                    if (state.wishListTotal.length > 0) {
                        let allUniversityIds = [];
                        for (let index = 0; index < state.wishListTotal.length; index++) {
                            let element = state.wishListTotal[index];
                            allUniversityIds = [...allUniversityIds, element.UNITID];
                        }
                        allUniversityIds = [...allUniversityIds]
                        state.wishListTotalUniIds = [...allUniversityIds]
                    }
                }
                state.wishlistLoading = false;
            })
            .addCase(getAllWishlist.rejected, (state) => {
                state.wishlistLoading = true;
            })

            .addCase(deleteWishlist.pending, (state) => {
                state.wishlistLoading = true;
            })
            .addCase(deleteWishlist.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.wishListTotal = state.wishListTotal.filter(
                        (wishList) => wishList._id !== action.payload.wishList._id
                    );
                    state.wishListTotal = [...state.wishListTotal]
                    if (state.wishListTotal.length > 0) {
                        let allUniversityIds = [];
                        for (let index = 0; index < state.wishListTotal.length; index++) {
                            let element = state.wishListTotal[index];
                            allUniversityIds = [...allUniversityIds, element.UNITID];
                        }
                        allUniversityIds = [...allUniversityIds]
                        state.wishListTotalUniIds = [...allUniversityIds]
                    }
                }
                state.wishlistLoading = false;
            })
            .addCase(deleteWishlist.rejected, (state) => {
                state.wishlistLoading = true;
            })

    },
});


export const {
    setprogramSearchObjects,
    setUniversitySearchObjects,
    setallUniversityIds,
    setUniversitySearchObjectsResult
} = testManagementSlice.actions;
export default testManagementSlice.reducer;