import React, { useState, useEffect } from "react";
import MenuBar from "./student_registation/MenuBar";
import { Affix } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
import RegistationComponents from "./student_registation/RegistationComponents";
import { useSelector } from "react-redux";

const StudentRegistation = () => {
  const navigate = useNavigate();
  const [menubar_Status, setMenubar_Status] = useState(false);
  const { loginData } = useSelector((store) => store.Athentication);
  useEffect(() => {
    if (loginData.currentFormStep === 11) {
      navigate("/dashbord/home");
    }
  }, [loginData]);

  return (
    <>
      <div className="registation_container">
        <Affix
          offsetTop={89}
          className={
            menubar_Status === true
              ? "registation_menu_bar_close"
              : "registation_menu_bar"
          }
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <MenuBar />

            <div className="toglebar">
              {menubar_Status === true ? (
                <>
                  <IoIosArrowDropright
                    className="icon_tobler currsor_po"
                    onClick={() => setMenubar_Status(!menubar_Status)}
                  />
                </>
              ) : (
                <>
                  <IoIosArrowDropleft
                    className="icon_tobler currsor_po"
                    onClick={() => setMenubar_Status(!menubar_Status)}
                  />
                </>
              )}
            </div>
          </div>
        </Affix>
        <div
          className={
            menubar_Status === true
              ? "registation_component_space_close"
              : "registation_component_space"
          }
        >
          <RegistationComponents />
        </div>
      </div>
    </>
  );
};

export default StudentRegistation;
