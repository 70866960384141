import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { studentDashboardPageChange } from "../../redux/studentDashboardPage/StudentDasboardSlice";

const HomeSlider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const [hover, setHover] = useState(2);
  const changeDiv = (value) => {
    setHover(value);
  };

  const navigationClick = () => {
    if (isAuth === true) {
      if (loginData.subscriptionstatus && loginData.noofTimeLogin > 0) {
        navigate("/dashbord/home");
        dispatch(studentDashboardPageChange(2));
      } else {
        navigate("/dashbord/welcome");
      }
    } else {
      navigate("/sign-in");
    }
  };
  return (
    <>
      <section
        className="h8_banner-area"
        style={{ paddingTop: "22px", paddingBottom: "35px" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-5 col-md-12">
              <div className="banner-content">
                <div className="section-area">
                  <span className="section-subtitle">
                    Gateway to US Universities
                  </span>
                  <h1 className="section-title">
                    World's 1st online US Universities{" "}
                    <span>
                      matching
                      <img src="/assets/img/banner/1/line.png" alt="" />
                    </span>{" "}
                    platform for International Students.
                  </h1>

                  <span
                    style={{
                      background: "#395BDF",
                    }}
                    className="theme-btn theme-btn-big"
                    onClick={() => navigationClick()}
                  >
                    Get Started
                  </span>

                  <div className="pricing_container pt-10">
                    <div className="snip1255 desktopversion">
                      <div
                        className={`plan ${hover === 1 ? "hover" : ""} `}
                        onMouseOver={() => changeDiv(1)}
                        onMouseLeave={() => changeDiv(2)}
                      >
                        <header>
                          <h4 className="plan-title">
                            {" "}
                            <span className="h4span">Primary</span>
                          </h4>
                          <div className="plan-cost">
                            <span className="plan-price">$20</span>
                          </div>
                        </header>
                        <div className="centerBoxText">
                          <ul className="plan-features">
                            <li>✔ Match University</li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`plan1 ${hover === 2 ? "hover1" : ""} `}
                        onMouseOver={() => changeDiv(2)}
                        onMouseLeave={() => changeDiv(2)}
                      >
                        <header>
                          <h4 className="plan-title">
                            <span className="h4span1">Popular</span>
                          </h4>
                          <div className="plan-cost">
                            <span className="plan-price">$30</span>
                          </div>
                        </header>
                        <div className="centerBoxText">
                          <ul className="plan-features">
                            <li>✔ Match University</li>
                            <li>✔ Application Prep</li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`plan2 ${hover === 3 ? "hover2" : ""} `}
                        onMouseOver={() => changeDiv(3)}
                        onMouseLeave={() => changeDiv(2)}
                      >
                        <header>
                          <h4 className="plan-title">
                            <span className="h4span2">Value</span>
                          </h4>
                          <div className="plan-cost">
                            <span className="plan-price">$50</span>
                          </div>
                        </header>
                        <div className="centerBoxText">
                          <ul className="plan-features">
                            <li>✔ Match University</li>
                            <li>✔ Application Prep</li>
                            <li>✔ Test Prep </li>
                            <li>✔ Profile Analysis </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-6 col-lg-6">
              <div
                className="h8_banner-img wow fadeInRight"
                data-wow-delay="0.5s"
              >
                <img src="/assets/img/banner/8/1.png" alt="" />
              </div>
            </div> */}
            <div className="col-xl-6 col-lg-6 d-lg-block pt-30">
              <div className="h2_banner-right pl-80">
                <div className="h2_banner-img">
                  <img src="/assets/img/banner/2/banner-bg.png" alt="" />
                </div>
                <div className="h2_banner-right-shape">
                  <img
                    className="h2_banner-shape-1"
                    src="/assets/img/banner/2/shape_1.png"
                    alt=""
                  />
                  <div className="inner-shpae-1">
                    <img
                      className="h2_banner-shape-2"
                      src="/assets/img/banner/2/shape_2.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* <div className="h2_banner-meta">
                  <div className="h2_banner-meta-info">
                    <span>Popular Course</span>
                    <h5>Start Learning Today!!</h5>
                  </div>
                  <div className="h2_banner-meta-rating">
                    <span>
                      <i className="fa-solid fa-star" />
                      Programs with CPT
                    </span>
                    <h5>Choose 'Value' subscription!!</h5>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="snip1255 mobileversion">
        <div
          className={`plan ${hover === 1 ? "hover" : ""} `}
          onMouseOver={() => changeDiv(1)}
          onMouseLeave={() => changeDiv(2)}
        >
          <header>
            <h4 className="plan-title">
              {" "}
              <span className="h4span">Primary</span>
            </h4>
            <div className="plan-cost">
              <span className="plan-price">$20</span>
            </div>
          </header>
          <div className="centerBoxText">
            <ul className="plan-features">
              <li>✔ Match University</li>
            </ul>
          </div>
        </div>
        <div
          className={`plan1 ${hover === 2 ? "hover1" : ""} `}
          onMouseOver={() => changeDiv(2)}
          onMouseLeave={() => changeDiv(2)}
        >
          <header>
            <h4 className="plan-title">
              <span className="h4span1">Popular</span>
            </h4>
            <div className="plan-cost">
              <span className="plan-price">$30</span>
            </div>
          </header>
          <div className="centerBoxText">
            <ul className="plan-features">
              <li>✔ Match University</li>
              <li>✔ Application Prep</li>
            </ul>
          </div>
        </div>
        <div
          className={`plan2 ${hover === 3 ? "hover2" : ""} `}
          onMouseOver={() => changeDiv(3)}
          onMouseLeave={() => changeDiv(2)}
        >
          <header>
            <h4 className="plan-title">
              <span className="h4span2">Value</span>
            </h4>
            <div className="plan-cost">
              <span className="plan-price">$50</span>
            </div>
          </header>
          <div className="centerBoxText">
            <ul className="plan-features">
              <li>✔ Match University</li>
              <li>✔ Application Prep</li>
              <li>✔ Test Prep </li>
              <li>✔ Profile Analysis </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSlider;
