import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Affix, Avatar } from "antd";
import logo from "./f1visalogo8.svg";
import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { signout } from "../../redux/authentication/AuthenticationSlice";
import { removeallStudentFormDara } from "../../redux/dashboard/PageRenderSlice";
import { removeStudentDetail } from "../../redux/StudentRegd/StudentRegdSlice";
import { studentDashboardPageChange } from "../../redux/studentDashboardPage/StudentDasboardSlice";

const Dashbord_Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isAuth === false) {
      navigate("/");
    }
  }, [isAuth]);

  const logoutClick = () => {
    dispatch(signout());
    dispatch(removeallStudentFormDara());
    dispatch(removeStudentDetail());
    navigate("/");
    setAnchorEl(null);
  };

  return (
    <>
      <Affix offsetTop={0.5}>
        <div className="dasbord_header_container">
          <div className="dashbord-logo">
            <Link to="/">
              <img src={logo} alt />
            </Link>
          </div>
          <div className="dashbord-menu_div">
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      size="large"
                      src="https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem   onClick={() => {
                  handleClose()
                  dispatch(studentDashboardPageChange(10));
                }}  >
                  <Avatar
                    size="medium"
                    className="mr-2"
                    src="https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg"
                  />
                  Profile
                </MenuItem>

                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate("/change-password");
                  }}
                >
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem> */}
                <MenuItem onClick={() => logoutClick()}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Sign Out
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
        </div>
      </Affix>
    </>
  );
};

export default Dashbord_Header;
