import React from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

const Pricing = () => {
  return (
    <>
      <section className="innerPage_price-area pt-10 pb-10">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  Pricing
                </span>
                <h2 className="section-title mb-0">Affordable Pricing Plans to Fit Every Student's Needs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="h2_price-item mb-30">
                <div className="h2_price-item-title">
                  <h5>Primary</h5>
                </div>
                <div className="h2_price-amount">
                  <del>$25.00</del>
                  <div className="h2_price-amount-info">
                    <h2>$20</h2>
                    <p>
                      <span>Per</span>
                      <span>3 Month</span>
                    </p>
                  </div>
                </div>
                <div className="h2_price-middle-info">
                  <p className="h2_price-middle-info-1">
                    Perfect for students who want foundational guidance to get
                    started on their admissions journey.
                  </p>
                </div>
                <div className="h2_price-button">
                  <Link>Enroll Now</Link>
                </div>
                <div className="h3_price-content">
                  <div className="h2_price-content-list">
                    <ul className="list_style">
                      <li>
                        <FaCheckCircle className="check" />
                        Access to essential university matching tool
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Basic online guidance
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Personalized university recommendations
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Essay and document preparation guidance
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Admission guidance
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Comprehensive guidance
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Detailed resources for TOEFL, IELTS, SAT, ACT, GRE, and
                        GMAT preparation with practice tests and feedback
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Complete online support, including interview prep
                        resources
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Continuous support until your arrival in the U.S.
                      </li>
                      {/* <li>
                        <AiFillCloseCircle className="non_check" />
                        Test Prep
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Profile Analysis
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="h2_price-item mb-30">
                <div className="h2_price-popular-tag">
                  <span>Most Popular</span>
                </div>
                <div className="h2_price-item-title">
                  <h5>Popular</h5>
                </div>
                <div className="h2_price-amount">
                  <del>$35.00</del>
                  <div className="h2_price-amount-info">
                    <h2>$30</h2>
                    <p>
                      <span>Per</span>
                      <span>3 Month</span>
                    </p>
                  </div>
                </div>
                <div className="h2_price-middle-info">
                  <p className="h2_price-middle-info-1">
                    Ideal for students who need a little more support in
                    crafting a standout application and navigating the admission
                    process.
                  </p>
                </div>
                <div className="h2_price-button">
                  <Link>Enroll Now</Link>
                </div>
                <div className="h3_price-content">
                  <div className="h2_price-content-list">
                    <ul className="list_style">
                      <li>
                        <FaCheckCircle className="check" />
                        Access to essential university matching tool
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Basic online guidance
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Personalized university recommendations
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Essay and document preparation guidance
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Admission guidance
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Comprehensive guidance
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Detailed resources for TOEFL, IELTS, SAT, ACT, GRE, and
                        GMAT preparation with practice tests and feedback
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Complete online support, including interview prep
                        resources
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Continuous support until your arrival in the U.S.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="h2_price-item mb-30">
                <div className="h2_price-item-title">
                  <h5>Value</h5>
                </div>
                <div className="h2_price-amount">
                  <del>$60.00</del>
                  <div className="h2_price-amount-info">
                    <h2>$50</h2>
                    <p>
                      <span>Per</span>
                      <span>3 Month</span>
                    </p>
                  </div>
                </div>
                <div className="h2_price-middle-info">
                  <p className="h2_price-middle-info-1">
                    The comprehensive package for students seeking full support
                    from start to finish.
                  </p>
                </div>
                <div className="h2_price-button">
                  <Link>Enroll Now</Link>
                </div>
                <div className="h2_price-content">
                  <div className="h2_price-content-list">
                    <ul className="list_style">
                      <li>
                        <FaCheckCircle className="check" />
                        Access to essential university matching tool
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Basic online guidance
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Personalized university recommendations
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Essay and document preparation guidance
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Admission guidance
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Comprehensive guidance
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Detailed resources for TOEFL, IELTS, SAT, ACT, GRE, and
                        GMAT preparation with practice tests and feedback
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Complete online support, including interview prep
                        resources
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Continuous support until your arrival in the U.S.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
