import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { Typography, Radio, Col, Button, Space } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";

import Header from "../../student_registation/components/Header";
import countries from "../../student_registation/json_datas/countries.json";
import cities from "../../student_registation/json_datas/states+cities.json";
import states from "../../student_registation/json_datas/countries+states.json";
import Main_draft_letter from "./draftletters/Main_draft_letter";
import programs_final from "../../student_registation/json_datas/Cip_finalsheet.json";

const DraftRecommendation = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const { totalUniversity } = useSelector((state) => state.testManagement);

  const [drafttype, setdrafttype] = useState("");
  const [drafttypeError, setdrafttypeError] = useState("");
  const [facultyName, setfacultyName] = useState("");
  const [facultyNameError, setfacultyNameError] = useState("");
  const [facultyTitle, setfacultyTitle] = useState("");
  const [facultyTitleError, setfacultyTitleError] = useState("");
  const [facultyDepartment, setfacultyDepartment] = useState("");
  const [facultyDepartmentError, setfacultyDepartmentError] = useState("");
  const [facultyInstitution, setfacultyInstitution] = useState("");
  const [facultyInstitutionError, setfacultyInstitutionError] = useState("");
  const [facultyCountry, setfacultyCountry] = useState("");
  const [facultyCountryError, setfacultyCountryError] = useState("");
  const [facultyCity, setfacultyCity] = useState("");
  const [facultyCityError, setfacultyCityError] = useState("");
  const [facultyState, setfacultyState] = useState("");
  const [facultyStateError, setfacultyStateError] = useState("");
  const [facultyZip, setfacultyZip] = useState("");
  const [facultyZipError, setfacultyZipError] = useState("");
  const [facultyEmail, setfacultyEmail] = useState("");
  const [facultyEmailcnf, setfacultyEmailcnf] = useState("");
  const [facultyEmailError, setfacultyEmailError] = useState("");
  const [mobile, setmobile] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [newdate, setDate] = useState("");
  const [universityName, setuniversityName] = useState("");
  const [universityError, setuniversityError] = useState("");
  const [selectedUniversity, setselectedUniversity] = useState("");
  const [showStatesByCountry, setshowStatesByCountry] = useState([]);
  const [showCityByStates, setshowCityByStates] = useState([]);

  const [specific_field, setspecific_field] = useState("");
  const [specific_fieldError, setspecific_fieldError] = useState("");
  const [specific_project, setspecific_project] = useState("");
  const [specific_projectError, setspecific_projectError] = useState("");

  const [noyears, setnoyears] = useState("");
  const [noyearsError, setnoyearsError] = useState("");
  const [specific_position, setspecific_position] = useState("");
  const [specific_positionError, setspecific_positionError] = useState("");
  const [specific_role, setspecific_role] = useState("");
  const [specific_roleError, setspecific_roleError] = useState("");

  const [specific_activities, setspecific_activities] = useState("");
  const [specific_activitiesError, setspecific_activitiesError] = useState("");

  const [specific_chalanges, setspecific_chalanges] = useState("");
  const [specific_chalangesError, setspecific_chalangesError] = useState("");

  const [selected_program, setselected_program] = useState("");
  const [selected_programError, setselected_programError] = useState("");

  const [button_press, setbutton_press] = useState(false);
  const [button_loading, setbutton_loading] = useState(false);
  const [show_results, setshow_results] = useState(false);
  const [data_use, setdata_use] = useState("");

  useEffect(() => {
    if (button_press === true) {
      if (drafttype === "") {
        setdrafttypeError("Required");
      } else {
        setdrafttypeError("");
      }
      if (facultyName === "") {
        setfacultyNameError("Required");
      } else {
        setfacultyNameError("");
      }
      if (facultyTitle === "") {
        setfacultyTitleError("Required");
      } else {
        setfacultyTitleError("");
      }
      if (facultyDepartment === "") {
        setfacultyDepartmentError("Required");
      } else {
        setfacultyDepartmentError("");
      }
      if (facultyInstitution === "") {
        setfacultyInstitutionError("Required");
      } else {
        setfacultyInstitutionError("");
      }
      if (facultyCountry === "") {
        setfacultyCountryError("Required");
      } else {
        setfacultyCountryError("");
      }
      if (facultyCity === "") {
        setfacultyCityError("Required");
      } else {
        setfacultyCityError("");
      }
      if (facultyState === "") {
        setfacultyStateError("Required");
      } else {
        setfacultyStateError("");
      }
      if (facultyZip === "") {
        setfacultyZipError("Required");
      } else {
        setfacultyZipError("");
      }
      if (facultyEmailcnf === "") {
        setfacultyEmailError("Required");
      } else {
        setfacultyEmailError("");
      }
      if (mobile === "") {
        setmobileError("Required");
      } else {
        setmobileError("");
      }

      if (universityName === "") {
        setuniversityError("Required");
      } else {
        setuniversityError("");
      }
      if (selected_program === "") {
        setselected_programError("Required");
      } else {
        setselected_programError("");
      }

      if (
        drafttype === "Research Potential" ||
        drafttype === "Passion and Enthusiasm for the Field"
      ) {
        if (specific_field === "") {
          setspecific_fieldError("Required");
        } else {
          setspecific_fieldError("");
        }
        if (specific_project === "") {
          setspecific_projectError("Required");
        } else {
          setspecific_projectError("");
        }
      }
      if (drafttype === "Overcoming Challenges") {
        if (specific_chalanges === "") {
          setspecific_chalangesError("Required");
        } else {
          setspecific_chalangesError("");
        }
      }
      if (drafttype === "Leadership and Extracurricular Activities") {
        if (noyears === "") {
          setnoyearsError("Required");
        } else {
          setnoyearsError("");
        }
        if (specific_position === "") {
          setspecific_positionError("Required");
        } else {
          setspecific_positionError("");
        }
        if (specific_role === "") {
          setspecific_roleError("Required");
        } else {
          setspecific_roleError("");
        }
      }
      if (
        drafttype === "Leadership and Extracurricular Activities" ||
        drafttype === "Interpersonal Skills and Teamwork"
      ) {
        if (specific_activities === "") {
          setspecific_activitiesError("Required");
        } else {
          setspecific_activitiesError("");
        }
      }
    }
  }, [
    button_press,
    specific_field,
    specific_project,
    specific_chalanges,
    noyears,
    specific_position,
    specific_role,
    specific_activities,
    facultyName,
    drafttype,
    facultyTitle,
    facultyDepartment,
    facultyInstitution,
    facultyCountry,
    facultyCity,
    facultyState,
    facultyZip,
    facultyEmailcnf,
    mobile,
    universityName,
    selected_program,
  ]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("recomended_draft"));

    if (data) {
      setdrafttype(data.drafttype);
      setfacultyName(data.facultyName);
      setfacultyTitle(data.facultyTitle);
      setfacultyDepartment(data.facultyDepartment);
      setfacultyInstitution(data.facultyInstitution);
      setfacultyCountry(data.facultyCountry);
      setfacultyCity(data.facultyCity);
      setfacultyState(data.facultyState);
      setfacultyZip(data.facultyZip);
      setfacultyEmail(data.facultyEmailcnf);
      setfacultyEmailcnf(data.facultyEmailcnf);
      setmobile(data.mobile);
      setuniversityName(data.universityName);
      setselectedUniversity(data.selectedUniversity);
      setselected_program(data.selected_program);

      setspecific_field(data.specificField);
      setspecific_project(data.specificProject);
      setnoyears(data.yearsno);
      setspecific_position(data.specificposition);
      setspecific_role(data.specificrole);
      setspecific_activities(data.specificactivities);
      setspecific_chalanges(data.specificChallanges);
    }

    const date = new Date();
    setDate(date);
  }, []);

  useEffect(() => {
    if (facultyCountry !== "") {
      const filterStae = states.find((data) => data.name === facultyCountry);
      setshowStatesByCountry([...filterStae.states]);
    }
  }, [facultyCountry]);

  useEffect(() => {
    if (facultyState !== "") {
      const filterStae = cities.find((data) => data.name === facultyState);
      setshowCityByStates([...filterStae.cities]);
    }
  }, [facultyState]);

  let show_programs = [];
  const program_sort = programs_final.sort((a, b) =>
    a.Title > b.Title ? 1 : -1
  );
  for (let index = 0; index < program_sort.length; index++) {
    let element = program_sort[index];
    const code = String(element.Code);

    if (code.length === 1) {
      show_programs = [...show_programs, element];
    } else {
      const last_two_digits = code.slice(-2);
      if (
        last_two_digits === "01" ||
        last_two_digits === "99" ||
        last_two_digits === "00"
      ) {
        show_programs = [...show_programs, element];
      }
    }
  }

  const validateEmail = async (e) => {
    const inputValue = e.target.value;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const email_Regex = /@[a-z]/;
    const emailtotalRegex = /.[a-z]{2,3}/;
    setfacultyEmail(inputValue);
    setfacultyEmailcnf("");
    let error = "";
    if (!email_Regex.test(inputValue)) {
      error = "email address @ is missing like examplegmail.com";
      setfacultyEmailError("email address @ is missing like examplegmail.com");
    } else if (!emailtotalRegex.test(inputValue)) {
      setfacultyEmailError("email address domain is missing like example@");
    } else if (!emailRegex.test(inputValue)) {
      setfacultyEmailError("*Error! you have entered invalid email.");
    } else {
      setfacultyEmailcnf(inputValue);
      setfacultyEmailError("");
    }
  };

  const verify_draft_generation = async (e) => {
    e.preventDefault();
    setbutton_press(true);
    let gender = "her";
    if (loginData.personalInformation.gender === "Male") {
      gender = "him";
    }
    let gender2 = "her";
    if (loginData.personalInformation.gender === "Male") {
      gender2 = "his";
    }
    let gender3 = "She";
    if (loginData.personalInformation.gender === "Male") {
      gender3 = "He";
    }

    let specificField = "";
    let specificProject = "";
    if (
      drafttype === "Research Potential" ||
      drafttype === "Passion and Enthusiasm for the Field"
    ) {
      specificField = specific_field;
      specificProject = specific_project;
    } else {
      specificField = " ";
      specificProject = " ";
    }

    let specificChallanges = "";
    if (drafttype === "Overcoming Challenges") {
      specificChallanges = specific_chalanges;
    } else {
      specificChallanges = " ";
    }

    let yearsno = "";
    let specificposition = "";
    let specificrole = "";
    if (drafttype === "Leadership and Extracurricular Activities") {
      yearsno = noyears;
      specificposition = specific_position;
      specificrole = specific_role;
    } else {
      yearsno = " ";
      specificposition = " ";
      specificrole = " ";
    }
    let specificactivities = "";
    if (
      drafttype === "Leadership and Extracurricular Activities" ||
      drafttype === "Interpersonal Skills and Teamwork"
    ) {
      specificactivities = specific_activities;
    } else {
      specificactivities = " ";
    }

    if (
      facultyName !== "" &&
      drafttype !== "" &&
      facultyTitle !== "" &&
      facultyDepartment !== "" &&
      facultyInstitution !== "" &&
      facultyCountry !== "" &&
      facultyCity !== "" &&
      facultyState !== "" &&
      facultyZip !== "" &&
      facultyEmailcnf !== "" &&
      mobile !== "" &&
      specificField !== "" &&
      specificProject !== "" &&
      specificChallanges !== "" &&
      yearsno !== "" &&
      specificposition !== "" &&
      specificrole !== "" &&
      specificactivities !== "" &&
      universityName !== "" &&
      selected_program !== ""
    ) {
      const formdara = {
        facultyName: facultyName,
        drafttype: drafttype,
        facultyTitle: facultyTitle,
        facultyDepartment: facultyDepartment,
        facultyInstitution: facultyInstitution,
        facultyCountry: facultyCountry,
        facultyCity: facultyCity,
        facultyState: facultyState,
        facultyZip: facultyZip,
        facultyEmailcnf: facultyEmailcnf,
        mobile: mobile,
        universityName: universityName,
        universityAddress: selectedUniversity.address,
        universityCountry: selectedUniversity,
        universityCity: selectedUniversity.city,
        universityState: selectedUniversity.state,
        universityZip: selectedUniversity.zipcode,
        gender: gender,
        gender2: gender2,
        gender3: gender3,
        selectedUniversity: selectedUniversity,
        newdate: newdate,
        selected_program: selected_program,
        specificField: specificField.trim(),
        specificProject: specificProject.trim(),
        specificChallanges: specificChallanges.trim(),
        yearsno: yearsno.trim(),
        specificposition: specificposition.trim(),
        specificrole: specificrole.trim(),
        specificactivities: specificactivities.trim(),
      };
      setdata_use(formdara);
      localStorage.setItem("recomended_draft", JSON.stringify(formdara));
      setshow_results(true);
      setbutton_loading(false);
      setbutton_press(false);
    } else {
      setshow_results(false);
      setbutton_loading(false);
    }
  };

  const draft_types = [
    { name: "General Academic Excellence" },
    { name: "Research Potential" },
    { name: "Leadership and Extracurricular Activities" },
    { name: "Overcoming Challenges" },
    { name: "Interpersonal Skills and Teamwork" },
    { name: "Passion and Enthusiasm for the Field" },
  ];
  const facult_title = [
    { name: "Dean" },
    { name: "Head" },
    { name: "Professor" },
    { name: "Lecturer" },
  ];
  const specific_rolesss = [
    { name: "Teacher" },
    { name: "Advisor" },
    { name: "Mentor" },
  ];

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Generate Draft Letter of Recommendation"
            description="Please generate this draft letter of recommendation based on your profile strengths. You can use more than one template and customize them before sending them to your recommenders. Ensure you enter complete data to generate an accurate letter of recommendation. Also, request your recommender to add their own content, if they can. Please request your faculty/recommender to email the letter directly to the University/Institution."
          />
          {show_results === false ? (
            <>
              <div className="inputs_container">
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Template Type"
                    select
                    className="input_style"
                    error={drafttypeError !== "" ? true : false}
                    helperText={drafttypeError}
                    value={drafttype}
                    onChange={(e) => setdrafttype(e.target.value)}
                    size="small"
                  >
                    {draft_types.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Faculty Name"
                    type="text"
                    id="text"
                    className="input_style"
                    error={facultyNameError !== "" ? true : false}
                    helperText={facultyNameError}
                    value={facultyName}
                    onChange={(e) => {
                      setfacultyName(e.target.value);
                    }}
                    autocomplete="off"
                    size="small"
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                    }}
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Title"
                    select
                    className="input_style"
                    error={facultyTitleError !== "" ? true : false}
                    helperText={facultyTitleError}
                    value={facultyTitle}
                    onChange={(e) => {
                      setfacultyTitle(e.target.value);
                    }}
                    size="small"
                  >
                    {facult_title.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Department Name in Full"
                    type="text"
                    id="text"
                    className="input_style"
                    error={facultyDepartmentError !== "" ? true : false}
                    helperText={facultyDepartmentError}
                    value={facultyDepartment}
                    onChange={(e) => {
                      setfacultyDepartment(e.target.value);
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Institution"
                    type="text"
                    id="text"
                    className="input_style"
                    error={facultyInstitutionError !== "" ? true : false}
                    helperText={facultyInstitutionError}
                    value={facultyInstitution}
                    onChange={(e) => {
                      setfacultyInstitution(e.target.value);
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Country"
                    select
                    className="input_style"
                    error={facultyCountryError !== "" ? true : false}
                    helperText={facultyCountryError}
                    value={facultyCountry}
                    onChange={(e) => setfacultyCountry(e.target.value)}
                    size="small"
                  >
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="State/Province"
                    select
                    className="input_style"
                    error={facultyStateError !== "" ? true : false}
                    helperText={facultyStateError}
                    value={facultyState}
                    onChange={(e) => setfacultyState(e.target.value)}
                    size="small"
                  >
                    {showStatesByCountry.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="City"
                    select
                    className="input_style"
                    error={facultyCityError !== "" ? true : false}
                    helperText={facultyCityError}
                    value={facultyCity}
                    onChange={(e) => setfacultyCity(e.target.value)}
                    size="small"
                  >
                    {showCityByStates.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Zip Code"
                    type="text"
                    id="text"
                    className="input_style"
                    error={facultyZipError !== "" ? true : false}
                    helperText={facultyZipError}
                    value={facultyZip}
                    onChange={(e) => {
                      setfacultyZip(e.target.value.replace(/\D/g, ""));
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Email address"
                    type="email"
                    id="text"
                    className="input_style"
                    error={facultyEmailError !== "" ? true : false}
                    helperText={facultyEmailError}
                    onChange={(e) => validateEmail(e)}
                    value={facultyEmail}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Phone Number"
                    type="text"
                    id="text"
                    className="input_style"
                    error={mobileError !== "" ? true : false}
                    helperText={mobileError}
                    value={mobile}
                    onChange={(e) => {
                      setmobile(e.target.value.replace(/\D/g, ""));
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                {drafttype === "Research Potential" ||
                drafttype === "Passion and Enthusiasm for the Field" ? (
                  <>
                    <div className="dn_input_box">
                      <TextField
                        label="Field"
                        type="text"
                        id="text"
                        className="input_style"
                        error={specific_fieldError !== "" ? true : false}
                        helperText={specific_fieldError}
                        value={specific_field}
                        onChange={(e) => {
                          setspecific_field(e.target.value);
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        label="Project"
                        type="text"
                        id="text"
                        className="input_style"
                        error={specific_projectError !== "" ? true : false}
                        helperText={specific_projectError}
                        value={specific_project}
                        onChange={(e) => {
                          setspecific_project(e.target.value);
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                  </>
                ) : null}

                {drafttype === "Overcoming Challenges" && (
                  <>
                    <div className="dn_input_box">
                      <TextField
                        label="Specific Challenge"
                        type="text"
                        id="text"
                        className="input_style"
                        error={specific_chalangesError !== "" ? true : false}
                        helperText={specific_chalangesError}
                        value={specific_chalanges}
                        onChange={(e) => {
                          setspecific_chalanges(e.target.value);
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="dn_input_box">
                      <div className="input_style"></div>
                    </div>
                  </>
                )}

                {drafttype === "Leadership and Extracurricular Activities" && (
                  <>
                    <div className="dn_input_box">
                      <TextField
                        label="Year"
                        type="text"
                        id="text"
                        className="input_style"
                        error={noyearsError !== "" ? true : false}
                        helperText={noyearsError}
                        value={noyears}
                        onChange={(e) => {
                          setnoyears(e.target.value.replace(/\D/g, ""));
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        label="Specific Role"
                        type="text"
                        id="text"
                        className="input_style"
                        error={specific_roleError !== "" ? true : false}
                        helperText={specific_roleError}
                        value={specific_role}
                        onChange={(e) => {
                          setspecific_role(e.target.value);
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Spesific Position"
                        select
                        className="input_style"
                        error={specific_positionError !== "" ? true : false}
                        helperText={specific_positionError}
                        value={specific_position}
                        onChange={(e) => setspecific_position(e.target.value)}
                        size="small"
                      >
                        {specific_rolesss.map((data, index) => (
                          <MenuItem key={index} value={data.name}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        label="Specific Activities"
                        type="text"
                        id="text"
                        className="input_style"
                        error={specific_activitiesError !== "" ? true : false}
                        helperText={specific_activitiesError}
                        value={specific_activities}
                        onChange={(e) => {
                          setspecific_activities(e.target.value);
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                  </>
                )}
                {drafttype === "Interpersonal Skills and Teamwork" && (
                  <>
                    <div className="dn_input_box">
                      <TextField
                        label="Specific Activities"
                        type="text"
                        id="text"
                        className="input_style"
                        error={specific_activitiesError !== "" ? true : false}
                        helperText={specific_activitiesError}
                        value={specific_activities}
                        onChange={(e) => {
                          setspecific_activities(e.target.value);
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="dn_input_box"></div>
                  </>
                )}

                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="University Name"
                    select
                    className="input_style"
                    error={universityError !== "" ? true : false}
                    helperText={universityError}
                    value={universityName}
                    onChange={(e) => {
                      setuniversityName(e.target.value);
                      const find_university = totalUniversity.find(
                        (data) => data.universityName === e.target.value
                      );
                      setselectedUniversity(find_university);
                    }}
                    size="small"
                  >
                    {totalUniversity.map((data, index) => (
                      <MenuItem key={index} value={data.universityName}>
                        {data.universityName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Please enter the subject this recommender taught you, if any"
                    select
                    className="input_style"
                    error={selected_programError !== "" ? true : false}
                    helperText={selected_programError}
                    value={selected_program}
                    onChange={(e) => {
                      setselected_program(e.target.value);
                    }}
                    size="small"
                  >
                    {show_programs.map((data, index) => (
                      <MenuItem key={index} value={data.Title}>
                        {data.Title}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
                <div className="button_container">
                  <Button
                    type="primary"
                    loading={button_loading}
                    iconPosition="end"
                    // icon={<PiPaperPlaneRight />}
                    size="large"
                    onClick={(e) => verify_draft_generation(e)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="inputs_container">
                <div className="button_container">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => setshow_results(false)}
                  >
                    Edit
                  </Button>
                </div>
                <div className="draft_header">
                  <span>Letter of {data_use.drafttype}</span>
                </div>
                <Main_draft_letter data_use={data_use} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DraftRecommendation;
