import React from "react";
import { useDispatch } from "react-redux";
import { studentDashboardPageChange } from "../../../redux/studentDashboardPage/StudentDasboardSlice";

const Header = ({ title, description }) => {
  const dispatch = useDispatch();

  const ObjectUniversityMatch = () => {
    dispatch(studentDashboardPageChange(2));
  };
  const profileUniversityMatch = () => {
    dispatch(studentDashboardPageChange(13));
  };
  return (
    <>
      <div className="stud_reg_head_cont">
        <span className="stud_reg_head_title">{title}</span>
        <span className="stud_reg_head_description">
          {description}{" "}
          {title === "By Profile" && (
            <span
              style={{
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
              onClick={() => profileUniversityMatch()}
            >
              Click here
            </span>
          )}
          {title === "By Objectives" && (
            <span
              style={{
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
              onClick={() => ObjectUniversityMatch()}
            >
              Click here
            </span>
          )}
        </span>
      </div>
    </>
  );
};

export default Header;
