import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { Space, Table, Tag, Affix, Button, Modal } from "antd";
import Header from "../../student_registation/components/Header";
import { getTestScheduleByStudentId } from "../../../redux/Test/TestSlice";
const PendingActions = () => {
  const { totalTestSchedule } = useSelector((store) => store.testManagement);
  const { loginData, isAuth } = useSelector((store) => store.Athentication);
  const dispatch = useDispatch();


  useEffect(() => {
    if (isAuth) {
      dispatch(getTestScheduleByStudentId(loginData));
    }
  }, [isAuth]);

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "index",
    //   key: "No",
    //   render: (text) => <span>{text}</span>,
    //   width: 70,
    // },
    {
      title: "Title",
      dataIndex: "testTitle",
      key: "Title",
      render: (text) => <Link>{text}</Link>,
      //   width: 300,
    },
    {
      title: "Test Code",
      dataIndex: "testCode",
      key: "Test Code",
      width: 150,
    },
    // {
    //   title: "  Test Type",
    //   key: "examType",
    //   dataIndex: "  Test Type",
    //   width: 100,
    // },
    {
      title: "Date",
      dataIndex: "scheduleDate",
      key: "Date",
      width: 150,
    },
    {
      title: "Time",
      dataIndex: "scheduleTimeVisible",
      key: "Time",
      width: 120,
    },
    {
      title: "Action",
      dataIndex: "programName",
      key: "Action",
      //   width: 300,

      render: (text, record) => (
        <span style={{ cursor: "pointer" }}>
          <Button type="primary">
            {" "}
            <a
              href={`https://f1visa-test-panel.netlify.app/login/${record.testId}`}
              // href={`http://localhost:3000/login/${record.testId}`}
              target="_blank"
            >
              Test Acess
            </a>
          </Button>
        </span>
      ),
    },
  ];
  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Pending Action(s)"
            description="Here are the steps you need to complete before applying for admissions."
          />

          <div className="inputs_container">
            <Table
              columns={columns}
              dataSource={totalTestSchedule}
              virtual
              scroll={{
                y: 400,
              }}
              //   onRow={(record, rowIndex) => {
              //     return {
              //       onClick: (event) => {
              //         console.log(event, "event");
              //       },
              //     };
              //   }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingActions;
