import React, { useState, useEffect } from "react";
import "./UniversityStyle.css";
import UniversityPrograms from "./UniversityPrograms";
import { useDispatch, useSelector } from "react-redux";
import { getsingleUniversity } from "../../redux/Test/TestSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const UniversityInfoPage = () => {
  const dispatch = useDispatch();
  const { selected_university } = useSelector((store) => store.Athentication);
  const { loginData } = useSelector((state) => state.Athentication);
  const [univwrsityData, setUniversityData] = useState("");
  const [univwrsityLoading, setUniversityLoading] = useState(true);

  const [temploading, setTempLoading] = useState(true);
  const [fetchedPrograms, setfetchedPrograms] = useState([]);

  useEffect(() => {
    if (selected_university) {
      const get_university = async () => {
        const get_data = await dispatch(
          getsingleUniversity(selected_university.UNITID)
        );
        if (get_data.payload.success === true) {
          const data = get_data.payload.university;
          setUniversityData(data);
          setUniversityLoading(false);
        }
      };
      get_university();
    }
  }, [selected_university]);

  useEffect(() => {
    if (univwrsityData !== "") {
      let eduLeve = "";
      if (loginData.degreeName === "Undergraduate") {
        eduLeve = "Bachelor's Degree";
      } else if (loginData.degreeName === "Graduate") {
        eduLeve = "Master's Degree";
      } else if (loginData.degreeName === "Doctoral") {
        eduLeve = "Doctoral Degree";
      }

      const fetchPrograms = async () => {
        const url = `${Baseurl}/api/v1/program/programbyuniv/${eduLeve}/${univwrsityData.UNITID}`;
        const fetchProgra = await axios.get(url);
        if (fetchProgra.data.success === true) {
          const progTotal = fetchProgra.data.programs;
          setTempLoading(false);
          setfetchedPrograms(progTotal);
        }
      };
      fetchPrograms();
    }
  }, [univwrsityData]);

  return (
    <>
      {univwrsityLoading === false ? (
        <>
          <div style={{ marginTop: "40px" }}></div>
          <div className="totalcontent">
            <div className="background_image">
              <img
                src="/assets/img/univerityinfobackground.jpg"
                alt="university_image"
              />
              <div className="bluecontainer">
                <h1>About {univwrsityData.universityName}</h1>
              </div>
            </div>

            <div className="paracontainer">
              <div>
                <p className="content_para">
                  The University of California was founded in 1868, born out of
                  a vision in the State Constitution of a university that would
                  “contribute even more than California’s gold to the glory and
                  happiness of advancing generations.”
                </p>
              </div>
            </div>

            <div className="desktop_down_content">
              <div className="to_give_margin">
                <div className="leftcontainer">
                  {/* Table University Programms */}
                  <div>
                    <UniversityPrograms fetchedPrograms={fetchedPrograms} />
                  </div>
                  <div className="imagecsideontainer">
                    <div>
                      <img
                        src="/assets/img/universityinogimages/menimage.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div className="sideparaside">
                      <div>
                        <h4> By the numbers</h4>
                      </div>
                      <div>
                        <p>
                          From faculty awards to the number of books in our
                          libraries, see Berkeley through its facts and figures.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="imagecsideontainer">
                    <div>
                      <img
                        src="/assets/img/universityinogimages/wall.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div className="sideparaside">
                      <div>
                        <h4>History and discoveries</h4>
                      </div>
                      <div>
                        <p>
                          The Free Speech Movement, wetsuits, a treatment for
                          malaria and earthquake science. You might be surprised
                          to learn what Berkeley has contributed to the world.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="imagecsideontainer">
                    <div>
                      <img
                        src="/assets/img/universityinogimages/experience.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div className="sideparaside">
                      <div>
                        <h4>Experience Berkeley</h4>
                      </div>
                      <div>
                        <p>
                          Right here, right now. Get a sense for what’s
                          happening on the ground at Berkeley.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="imagecsideontainer">
                    <div>
                      <img
                        src="/assets/img/universityinogimages/traditions.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div className="sideparaside">
                      <div>
                        <h4>Traditions</h4>
                      </div>
                      <div>
                        <p>
                          Explore the rites, rituals and rallies that become a
                          part of every Berkeley student’s campus experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="imagecsideontainer">
                    <div>
                      <img
                        src="/assets/img/universityinogimages/visit.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div className="sideparaside">
                      <div>
                        <h4>Visit</h4>
                      </div>
                      <div>
                        <p>
                          Ready to see Berkeley for yourself? Find out about
                          daily campus tours, parking and public transportation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="imagecsideontainer">
                    <div>
                      <img
                        src="/assets/img/universityinogimages/administration.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div className="sideparaside">
                      <div>
                        <h4>Leadership</h4>
                      </div>
                      <div>
                        <p>
                          See a complete list of campus administrators and
                          deans, and find unit-by-unit organizational charts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightcontainer">
                  <div>
                    <div className="sidelargeimage">
                      <img
                        src="/assets/img/universityinogimages/thatsberkeley.webp"
                        alt="error"
                        className="imagetower"
                      />
                    </div>
                    <div className="containeruc">
                      <div className="contentwhatt">
                        <h5>
                          What happens at UC Berkeley isn’t magic. It just feels
                          that way.
                        </h5>
                      </div>
                    </div>
                    <div className="sub_data">
                      <div className="subconttainertoflex">
                        <div className="inside">
                          <div className="hexagon">
                            <h3>184</h3>
                          </div>
                          <div>
                            <p>Academic Departments and Programs</p>
                          </div>
                        </div>
                        <div className="inside">
                          <div className="circle">
                            <h3>19.4 to 1</h3>
                          </div>
                          <div>
                            <p>Student-to-faculty ratio</p>
                          </div>
                        </div>
                      </div>
                      <div className="subconttainertoflex">
                        <div className="inside">
                          <div className="circle">
                            <h3 className="nine">9</h3>
                          </div>
                          <div>
                            <p>Nobel Prizes held by current faculty</p>
                          </div>
                        </div>

                        <div className="inside">
                          <div className="hexagon">
                            <h3>30%</h3>
                          </div>
                          <div>
                            <p>First generation first-year students</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile_versionsss">
              <div className="top_contentss">
                {/* Table University Programs */}
                <div>{/* <UniversityPrograms /> */}</div>
                <div className="conaatinersubmon">
                  <div className="conatiner_mobile">
                    <div className="imagofmobile">
                      <img
                        src="/assets/img/universityinogimages/menimage.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div>
                      <div className="conentsby">
                        <h4> By the numbers</h4>
                      </div>
                      <div>
                        <p className="facultycolor">
                          From faculty awards to the number of books in our
                          libraries, see Berkeley through its facts and figures.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="conaatinersubmon">
                  <div className="conatiner_mobile">
                    <div className="imagofmobile">
                      <img
                        src="/assets/img/universityinogimages/wall.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div>
                      <div className="conentsby">
                        <h4>History and discoveries</h4>
                      </div>
                      <div>
                        <p className="facultycolor">
                          The Free Speech Movement, wetsuits, a treatment for
                          malaria and earthquake science. You might be surprised
                          to learn what Berkeley has contributed to the world.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="conaatinersubmon">
                  <div className="conatiner_mobile">
                    <div className="imagofmobile">
                      <img
                        src="/assets/img/universityinogimages/experience.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div>
                      <div className="conentsby">
                        <h4>Experience Berkeley</h4>
                      </div>
                      <div>
                        <p className="facultycolor">
                          Right here, right now. Get a sense for what’s
                          happening on the ground at Berkeley.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="conaatinersubmon">
                  <div className="conatiner_mobile">
                    <div className="imagofmobile">
                      <img
                        src="/assets/img/universityinogimages/traditions.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div>
                      <div className="conentsby">
                        <h4>Traditions</h4>
                      </div>
                      <div>
                        <p className="facultycolor">
                          Explore the rites, rituals and rallies that become a
                          part of every Berkeley student’s campus experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="conaatinersubmon">
                  <div className="conatiner_mobile">
                    <div className="imagofmobile">
                      <img
                        src="/assets/img/universityinogimages/visit.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div>
                      <div className="conentsby">
                        <h4>Visit</h4>
                      </div>
                      <div>
                        <p className="facultycolor">
                          Ready to see Berkeley for yourself? Find out about
                          daily campus tours, parking and public transportation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="conaatinersubmon">
                  <div className="conatiner_mobile">
                    <div className="imagofmobile">
                      <img
                        src="/assets/img/universityinogimages/administration.jpg"
                        alt="numbers"
                        className="imageforsort"
                      ></img>
                    </div>
                    <div>
                      <div className="conentsby">
                        <h4>Leadership</h4>
                      </div>
                      <div>
                        <p className="facultycolor">
                          See a complete list of campus administrators and
                          deans, and find unit-by-unit organizational charts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="down_contentss">
                <div>
                  <div>
                    <img
                      src="/assets/img/universityinogimages/thatsberkeley.webp"
                      alt="error"
                      className="imagetower"
                    />
                  </div>
                  <div>
                    <div className="mobilehappens">
                      <h5>
                        What happens at UC Berkeley isn’t magic. It just feels
                        that way.
                      </h5>
                    </div>
                  </div>
                  <div className="totaldowncontemt">
                    <div className="subcontainerrssmobile">
                      <div className="innersub">
                        <div className="hexagon1">
                          <h3>184</h3>
                        </div>
                        <div>
                          <p>Academic Departments and Programs</p>
                        </div>
                      </div>
                      <div className="innersub">
                        <div className="circle1">
                          <h3>19.4 to 1</h3>
                        </div>
                        <div>
                          <p>Student-to-faculty ratio</p>
                        </div>
                      </div>
                    </div>
                    <div className="subcontainerrssmobile">
                      <div className="innersub">
                        <div className="circle1">
                          <h3 className="nine">9</h3>
                        </div>
                        <div>
                          <p>Nobel Prizes held by current faculty</p>
                        </div>
                      </div>
                      <div className="innersub">
                        <div className="hexagon1">
                          <h3>30%</h3>
                        </div>
                        <div>
                          <p>First generation first-year students</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UniversityInfoPage;
