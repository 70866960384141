import React, { useEffect, useState, useRef } from "react";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import { CiCircleInfo } from "react-icons/ci";
import SingleUniversityCard from "./university/SingleUniversityCard";
import NewSingleCardUniversity from "./university/NewSingleCardUniversity";
import Header from "./student_registation/components/Header";

const UniversityByProfile = () => {
  const {
    dreamUniversityTotal,
    reachUniversityTotal,
    safeUniversityTotal,
    isUniversityAvailableLoading,
  } = useSelector((store) => store.Athentication);

  return (
    <>
      <div
        className="main_container"
        style={{
          display: "flex",  
          flexDirection: "column",
        }}
      >
        <Header
          title="By Profile"
          description={`This option allows you to choose universities that best suits
                  your preferences based on your skills, work experience and
                  employablility during study`}
        />
        <div className="container_box max_height_cont background_style ">
          <div style={{ padding: "1vh" }}>
            {isUniversityAvailableLoading === false ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h6>Loading...</h6>
                </div>
              </>
            ) : (
              <>
                <div className="unidispconta mt-2">
                  <div className="unidisBox1">
                    <div className="imagebox">
                      <img
                        src="/assets/img/dream-new.svg"
                        height="100"
                        alt=""
                      />
                    </div>
                    <div className="textbox">
                      <h2 style={{ color: "#997fe6" }}>
                        {dreamUniversityTotal.length}{" "}
                        <CiCircleInfo
                          data-tooltip-id="my-tooltip-1"
                          style={{
                            fontSize: "2.6vh",
                            marginLeft: "3vh",
                            cursor: "pointer",
                          }}
                        />
                      </h2>
                      <h4 style={{ color: "#997fe6" }}>Dream</h4>
                    </div>
                  </div>
                  <div className="unidisBox2">
                    <div className="imagebox">
                      <img
                        src="/assets/img/reach-new.svg"
                        height="100"
                        alt=""
                      />
                    </div>
                    <div className="textbox">
                      <h2 style={{ color: "#ff8e37" }}>
                        {reachUniversityTotal.length}{" "}
                        <CiCircleInfo
                          data-tooltip-id="my-tooltip-1"
                          style={{
                            fontSize: "2.6vh",
                            marginLeft: "3vh",
                            cursor: "pointer",
                          }}
                        />
                      </h2>
                      <h4 style={{ color: "#ff8e37" }}>Reach</h4>
                    </div>
                  </div>
                  <div className="unidisBox3">
                    <div className="imagebox">
                      <img src="/assets/img/safe-new.svg" height="100" alt="" />
                    </div>
                    <div className="textbox">
                      <h2 style={{ color: "#57d3db" }}>
                        {safeUniversityTotal.length}{" "}
                        <CiCircleInfo
                          data-tooltip-id="my-tooltip-1"
                          style={{
                            fontSize: "2.6vh",
                            marginLeft: "3vh",
                            cursor: "pointer",
                          }}
                        />
                      </h2>
                      <h4 style={{ color: "#57d3db" }}>Safe</h4>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between mb-1">
                  <div className="uniheader">
                    <img
                      src="/assets/images/dream-new.svg"
                      height="100"
                      alt=""
                    />
                    <h1>{dreamUniversityTotal.length} Dream Universities</h1>
                  </div>
                </div>
                <div>
                  <Swiper
                    mousewheel={true}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    navigation={false}
                    modules={[Pagination]}
                    keyboard={true}
                    draggable={true}
                    effect={"fade"}
                    loop={true}
                    autoplay={{
                      delay: 1000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                      360: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      460: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      720: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1080: {
                        slidesPerView: 4,
                        spaceBetween: 4,
                      },
                    }}
                    className="mySwiper"
                  >
                    {dreamUniversityTotal &&
                      dreamUniversityTotal
                        .slice(0, 10)
                        .map((uiversity, index) => (
                          <SwiperSlide key={index}>
                            <>
                              <NewSingleCardUniversity
                                uiversity={uiversity}
                                index={index}
                              />
                            </>
                          </SwiperSlide>
                        ))}
                  </Swiper>
                </div>
                <div className="flex flex-wrap justify-between mb-1">
                  <div className="uniheader">
                    <img
                      src="/assets/images/reach-new.svg"
                      height="100"
                      alt=""
                    />
                    <h1>{reachUniversityTotal.length} Reach Universities</h1>
                  </div>
                </div>
                <Swiper
                  mousewheel={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  navigation={false}
                  modules={[Pagination]}
                  keyboard={true}
                  draggable={true}
                  effect={"fade"}
                  loop={true}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  breakpoints={{
                    360: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    460: {
                      slidesPerView: 1,
                      spaceBetween: 5,
                    },
                    720: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1080: {
                      slidesPerView: 4,
                      spaceBetween: 7,
                    },
                  }}
                  className="mySwiper"
                >
                  {reachUniversityTotal &&
                    reachUniversityTotal
                      .slice(0, 10)
                      .map((uiversity, index) => (
                        <SwiperSlide key={index}>
                          <>
                            <NewSingleCardUniversity
                              uiversity={uiversity}
                              index={index}
                            />
                          </>
                        </SwiperSlide>
                      ))}
                </Swiper>
                <div className="flex flex-wrap justify-between mb-1">
                  <div className="uniheader">
                    <img
                      src="/assets/images/safe-new.svg"
                      height="100"
                      alt=""
                    />
                    <h1>{safeUniversityTotal.length} Safe Universities</h1>
                  </div>
                </div>
                <Swiper
                  mousewheel={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  navigation={false}
                  modules={[Pagination]}
                  keyboard={true}
                  draggable={true}
                  effect={"fade"}
                  loop={true}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  breakpoints={{
                    360: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    460: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    720: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                    1080: {
                      slidesPerView: 4,
                      spaceBetween: 7,
                    },
                  }}
                  className="mySwiper"
                >
                  {safeUniversityTotal &&
                    safeUniversityTotal.slice(0, 10).map((uiversity, index) => (
                      <SwiperSlide key={index}>
                        <>
                          <NewSingleCardUniversity
                            uiversity={uiversity}
                            index={index}
                          />
                        </>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip
        id="my-tooltip-1"
        place="right"
        variant="info"
        content="Academic match is provided on a rating scale from 0-100 (100 is the best match) "
        style={{
          width: "50vh",
        }}
      />
    </>
  );
};

export default UniversityByProfile;
