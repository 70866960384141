import React from "react";
import { Button, Result } from "antd";

const SubscriptionSucces = () => {
  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Result
            status="success"
            title="Successfully Purchased Cloud Server ECS!"
            subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={[
              <Button type="primary" key="console">
                Save & continue
              </Button>,
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default SubscriptionSucces;
