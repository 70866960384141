import React, { useState, useEffect } from "react";
import { Affix } from "antd";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
import DashBordMenuBar from "./dashbord/DashBordMenuBar";
import DashBordComponents from "./dashbord/DashBordComponents";

const StudentDashBord = () => {
  const [menubar_Status, setMenubar_Status] = useState(false);
  return (
    <>
      <div className="registation_container">
        <Affix
          offsetTop={87}
          className={
            menubar_Status === true
              ? "registation_menu_bar_close"
              : "registation_menu_bar"
          }
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <DashBordMenuBar />

            <div className="toglebar">
              {menubar_Status === true ? (
                <>
                  <IoIosArrowDropright
                    className="icon_tobler currsor_po"
                    onClick={() => setMenubar_Status(!menubar_Status)}
                  />
                </>
              ) : (
                <>
                  <IoIosArrowDropleft
                    className="icon_tobler currsor_po"
                    onClick={() => setMenubar_Status(!menubar_Status)}
                  />
                </>
              )}
            </div>
          </div>
        </Affix>
        <div
          className={
            menubar_Status === true
              ? "registation_component_space_close"
              : "registation_component_space"
          }
        >
          <DashBordComponents />
        </div>
      </div>
    </>
  );
};

export default StudentDashBord;
