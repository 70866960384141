import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  HomeTwoTone,
} from "@ant-design/icons";
import {
  FaRegUser,
  FaPlaneDeparture,
  FaUniversity,
  FaRegAddressCard,
} from "react-icons/fa";
import { MdMonetizationOn, MdJoinFull } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { GrScorecard } from "react-icons/gr";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaObjectUngroup } from "react-icons/fa6";
import { Menu } from "antd";
import { studentDashboardPageChange } from "../../redux/studentDashboardPage/StudentDasboardSlice";
import { MdTravelExplore } from "react-icons/md";
import { IoBookOutline } from "react-icons/io5";
import { PiExamLight } from "react-icons/pi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoInformationSharp } from "react-icons/io5";
import { BiLogOut } from "react-icons/bi";
import { SiGoogleclassroom } from "react-icons/si";
import { RiDraftLine } from "react-icons/ri";
import { LiaUniversitySolid } from "react-icons/lia";
import { FaRegFileAlt } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import { PiStudentDuotone } from "react-icons/pi";

const DashBordMenuBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginData } = useSelector((store) => store.Athentication);
  const { dashboardPageStatus } = useSelector(
    (store) => store.StudentDashboard
  );

  const [stateOpenKeys, setStateOpenKeys] = useState(["2", "23"]);

  const items = [
    {
      key: "1",
      icon: <HomeTwoTone />,
      label: "Dashboard",
      form_step: 0,
    },
    {
      key: "2",
      icon: <FaRegUser />,
      label: " Search Programs",
      form_step: 1,
    },
    {
      key: "3",
      icon: <FaPlaneDeparture />,
      label: "Match Universities",
      form_step: 2,
    },
    {
      key: "4",
      icon: <FaUniversity />,
      label: "Generate Draft Personal Statement(SOP)",
      form_step: 3,
    },
    {
      key: "5",
      icon: <PiStudentBold />,
      label: "Generate Draft  Letter of Recommendation",
      form_step: 4,
    },
    {
      key: "6",
      icon: <MdMonetizationOn />,
      label: "Service Request",
      form_step: 5,
    },
    {
      key: "7",
      icon: <MdMonetizationOn />,
      label: "Test PREP",
      form_step: 6,
    },
    {
      key: "8",
      icon: <FaRegAddressCard />,
      label: " Apply DIRECT",
      form_step: 7,
    },
    {
      key: "9",
      icon: <IoMdInformationCircleOutline />,
      label: "Pending Action(s)",
      form_step: 8,
    },
    {
      key: "10",
      icon: <FaObjectUngroup />,
      label: "Renew Subscription",
      form_step: 9,
    },
  ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const navigationclick = (value) => {
    const nav_link = value.item.props.form_step;
    dispatch(studentDashboardPageChange(nav_link));
  };
  return (
    <>
      {/* <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        openKeys={stateOpenKeys}
        on`   ``````1````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````'penChange={onOpenChange}
        onClick={(e) => navigationclick(e)}
        style={{}}
        items={items}
      /> */}
      <Menu mode="inline" style={{ width: "100%" }}>
        {loginData.noofTimeLogin === 0 && (
          <Menu.Item
            icon={<PiStudentDuotone />}
            key="1"
            onClick={() => dispatch(studentDashboardPageChange(0))}
          >
            Dashboard
          </Menu.Item>
        )}
        <Menu.Item
          icon={<MdTravelExplore />}
          key="3"
          onClick={() => dispatch(studentDashboardPageChange(2))}
        >
          Match Universities
        </Menu.Item>
        <Menu.Item
          icon={<SiGoogleclassroom />}
          key="2"
          onClick={() => dispatch(studentDashboardPageChange(1))}
        >
          Search Programs
        </Menu.Item>

        {loginData.planName === "Popular" || loginData.planName === "Value" ? (
          <>
            <Menu.Item
              icon={<RiDraftLine />}
              key="4"
              onClick={() => dispatch(studentDashboardPageChange(3))}
              className="two-line-text"
            >
              Generate Draft
              <br />
              Personal Statement(SOP)
            </Menu.Item>
            <Menu.Item
              icon={<FaRegFileAlt />}
              key="5"
              onClick={() => dispatch(studentDashboardPageChange(4))}
              className="two-line-text"
            >
              Generate Draft <br />
              Letter of Recommendation
            </Menu.Item>
          </>
        ) : (
          <></>
        )}
        <Menu.Item
          icon={<IoBookOutline />}
          key="6"
          onClick={() => dispatch(studentDashboardPageChange(5))}
        >
          Service Request
        </Menu.Item>
        <Menu.Item
          icon={<IoBookOutline />}
          key="7"
          onClick={() => dispatch(studentDashboardPageChange(6))}
        >
          Test PREP
        </Menu.Item>
        <Menu.Item
          icon={<LiaUniversitySolid />}
          key="8"
          onClick={() => dispatch(studentDashboardPageChange(7))}
        >
          Apply DIRECT
        </Menu.Item>
        <Menu.Item
          icon={<MdOutlinePayments />}
          key="9"
          onClick={() => dispatch(studentDashboardPageChange(8))}
        >
          Pending Action(s)
        </Menu.Item>
        <Menu.Item
          icon={<MdOutlinePayments />}
          key="10"
          onClick={() => dispatch(studentDashboardPageChange(9))}
        >
          Renew Subscription
        </Menu.Item>
        <Menu.Item key="11"></Menu.Item>
      </Menu>
    </>
  );
};

export default DashBordMenuBar;
