import React, { useState } from "react";
import SelectDegree from "./signup/SelectDegree";
import FillInfoDetails from "./signup/FillInfoDetails";

const SignUp = () => {
  const [signupStatus, setSignupStatus] = useState(1);
  const [degreeName, setDegreeName] = useState("");
  const [questionResponse, setQuestionResponse] = useState(false);

  const changeSign_up_status = (value, degree, respo) => {
    setSignupStatus(value);
    setDegreeName(degree);
    setQuestionResponse(respo);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 1: {
        return (
          <>
            <SelectDegree changeSign_up_status={changeSign_up_status} />
          </>
        );
      }
      case 2: {
        return (
          <>
            <FillInfoDetails
              degreeName={degreeName}
              questionResponse={questionResponse}
            />
          </>
        );
      }

      default:
        return (
          <>
            <SelectDegree />
          </>
        );
    }
  };
  return <>{renderSwitch(signupStatus)}</>;
};

export default SignUp;
