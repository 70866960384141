import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { compress, decompress } from 'lz-string';

const initialState = {
  isAuth: JSON.parse(localStorage.getItem("loginData"))
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,

  loginData: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  email: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).email
    : "",
  firstname: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).firstname
    : "",
  lastname: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).lastname
    : "",
  birthdate: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).birthdate
    : "",
  password_reset_email: localStorage.getItem("password_reset_email")
    ? JSON.parse(localStorage.getItem("password_reset_email"))
    : "",

  dreamUniversityTotal: localStorage.getItem("dreamUniversityTotal")
    ? JSON.parse(decompress(localStorage.getItem("dreamUniversityTotal")))
    : [],
  reachUniversityTotal: localStorage.getItem("reachUniversityTotal")
    ? JSON.parse(decompress(localStorage.getItem("reachUniversityTotal")))
    : [],
  safeUniversityTotal: localStorage.getItem("safeUniversityTotal")
    ? JSON.parse(decompress(localStorage.getItem("safeUniversityTotal")))
    : [],

  programsbystudTotal: localStorage.getItem("programsbystudTotal")
    ? JSON.parse(decompress(localStorage.getItem("programsbystudTotal")))
    : [],
  isUniversityLoading: true,
  isUniversityAvailableLoading:
    localStorage.getItem("dreamUniversityTotal") ||
      localStorage.getItem("reachUniversityTotal") ||
      localStorage.getItem("safeUniversityTotal")
      ? true : false,

  isProgramstdLoading: true,
  isProgramstdAvailableLoading: false,
  buttonLoading: false,

  selected_program: localStorage.getItem("selected_program")
    ? JSON.parse(localStorage.getItem("selected_program"))
    : "",

  selected_university: localStorage.getItem("selected_university")
    ? JSON.parse(localStorage.getItem("selected_university"))
    : "",

};

export const studentRegistation = createAsyncThunk(
  "student/studentRegistation",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/student/register`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("student Not create");
    }
  }
);
export const student_reset_password = createAsyncThunk(
  "student/student_reset_password",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/student/reset_password`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("student Not create");
    }
  }
);
export const student_change_password = createAsyncThunk(
  "student/student_change_password",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/student/change_password`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("student Not create");
    }
  }
);

export const studentLoginBypassword = createAsyncThunk(
  "student/studentLoginBypassword",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not found",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/student/loginwithpassword`;
      resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUniversity_by_profile = createAsyncThunk(
  "Athentication/getUniversity_by_profile",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not found",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/university/university-search-by-profile`;
      resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getUniversity_by_object = createAsyncThunk(
  "Athentication/getUniversity_by_object",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not found",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/university/university-search-by-objective`;
      resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getProgramsstdList = createAsyncThunk(
  "Athentication/getProgramsstdList",
  async (eduLeve, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/program/programby/${eduLeve}`;
      const resp = await axios(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Blog Not Found");
    }
  }
);
// to post the contact us ======================


export const studentContactUs = createAsyncThunk(
  "student/studentContactUs",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/contactUs/new`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("student Not create");
    }
  }
);

const AthenticationSlice = createSlice({
  name: "Athentication",
  initialState,
  reducers: {
    setSelectProgram(state, action) {
      state.selected_program = action.payload
      localStorage.setItem("selected_program", JSON.stringify(state.selected_program));
    },
    setSelectUniversity(state, action) {
      state.selected_university = action.payload
      localStorage.setItem("selected_university", JSON.stringify(state.selected_university));
    },
    signin(state, action) {
      state.isAuth = action.payload.isAuth;
      state.email = action.payload.Email;
      state.firstname = action.payload.Firstname;
      state.lastname = action.payload.Lastname;
      state.birthdate = action.payload.Birthdate;
      state.loginData = action.payload;
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
    },
    signout(state) {
      state.loginData = "";
      state.email = "";
      state.firstname = "";
      state.lastname = "";
      state.birthdate = "";
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
      state.isAuth = false;
    },
    SetPassword_reset_email(state, action) {
      state.password_reset_email = action.payload;
      localStorage.setItem("password_reset_email", JSON.stringify(state.password_reset_email));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProgramsstdList.pending, (state) => {
        state.isProgramstdLoading = true;
      })
      .addCase(getProgramsstdList.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.programsbystudTotal = action.payload.programs;
          localStorage.setItem("programsbystudTotal", compress(JSON.stringify(state.programsbystudTotal)));
        }
        state.isProgramstdLoading = false;
        state.isProgramstdAvailableLoading = true;
      })
      .addCase(getProgramsstdList.rejected, (state) => {
        state.isProgramstdLoading = true;
      })
      .addCase(getUniversity_by_profile.pending, (state) => {
        state.isUniversityLoading = true;
      })
      .addCase(getUniversity_by_profile.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.dreamUniversityTotal = action.payload.dreamUniversity;
          state.reachUniversityTotal = action.payload.reachUniversity;
          state.safeUniversityTotal = action.payload.safeUniversity;
          localStorage.setItem("dreamUniversityTotal", compress(JSON.stringify(state.dreamUniversityTotal)));
          localStorage.setItem("reachUniversityTotal", compress(JSON.stringify(state.reachUniversityTotal)));
          localStorage.setItem("safeUniversityTotal", compress(JSON.stringify(state.safeUniversityTotal)));
        }
        state.isUniversityLoading = false;
        state.isUniversityAvailableLoading = true;
      })
      .addCase(getUniversity_by_profile.rejected, (state) => {
        state.isUniversityLoading = true;
      })
  }
});

export const { signin, signout, SetPassword_reset_email, setSelectUniversity, setSelectProgram } = AthenticationSlice.actions;
export const authActions = AthenticationSlice.actions;
export default AthenticationSlice.reducer;
