import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import { updateBlog } from "../redux/blog/BlogSlice";
import moment from "moment/moment";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaLongArrowAltUp } from "react-icons/fa";
import { Typography, message } from "antd";

const BlogDetails = () => {
  const { blogList } = useSelector((store) => store.Blog);
  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const [detailBlog, setDetailBlog] = useState("");
  const [userComment, setUserComment] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const foundBlog = blogList.find(
      (individualBlog) => individualBlog.slugUrl === param.slugUrl
    );
    if (foundBlog) {
      setDetailBlog(foundBlog);
    }
  }, [blogList, param.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    if (isAuth === true) {
      if (userComment !== "") {
        const blogcomment = [
          ...detailBlog.review,
          {
            userName: `${loginData.firstname} ${loginData.lastname}`,
            userEmail: loginData.email,
            blogComment: userComment,
          },
        ];
        const formData = {
          _id: detailBlog._id,
          review: blogcomment,
        };
        const postcomment = await dispatch(updateBlog(formData));
        if (postcomment.payload.success) {
          messageApi.open({
            type: "success",
            content: "Your comment send successfully",
          });
          setDetailBlog(postcomment.payload.blog);
          setbuttonPress(false);
          setUserComment("");
        }
      }
    } else {
      navigate("/sign-in");
    }
  };

  const categoryArray = [
    { name: "General" },
    { name: "Latest F1 News" },
    { name: "STEM Programs" },
    { name: "Student Loans" },
    { name: "Application Information" },
    { name: "F1/F2 Visa" },
    { name: "I-20 Transfer" },
    { name: "Health Insurance" },
    { name: "Student/CPT Employment" },
    { name: "SEVIS Related" },
    { name: "Miscellaneous" },
  ];
  return (
    <>
      {contextHolder}
      {detailBlog ? (
        <section className="blog_details-area pt-10">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8">
                <div className="blog_details-wrap mb-60">
                  <div className="blog_details-top mb-20">
                    <h3 className="blog_details-title">
                      {detailBlog.blogTitle}
                    </h3>
                    <div className="blog_details-meta">
                      <div className="blog_details-author">
                        <div className="blog_details-author-img">
                          <img src={detailBlog.avatar} alt />
                        </div>
                        <div className="blog_details-author-info">
                          <span>Admin</span>
                          <h5>
                            <Link>F1 Visa</Link>
                          </h5>
                        </div>
                      </div>
                      <div className="blog_details-category">
                        <span>Categories</span>
                        <h5>
                          <Link>General</Link>
                        </h5>
                      </div>
                      <div className="blog_details-category">
                        <span>Publish</span>
                        <h5>
                          <Link>
                            {" "}
                            {moment(detailBlog.createdAt).format("MMM Do YY")}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="blog_details-img image_align mb-40">
                    {/* <img src="assets/img/blog/details/1.jpg" alt /> */}
                    <img src={detailBlog.slider[0]} alt />
                  </div>
                  <div className="blog_details-content">
                    {detailBlog.blogarray.map((content, conindex) => (
                      <div key={conindex}>
                        {content.thumbnail && (
                          <div
                            className="blog_details-highlight"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="blog_details-highlight-img ">
                              <img src={content.thumbnail} alt />
                            </div>
                          </div>
                        )}

                        <div className="blog_details-inner-text">
                          {/* <h5>British Airways flight 38 from Beijing</h5> */}
                          <p
                            className="mb-40 paragraphs"
                            dangerouslySetInnerHTML={{
                              __html: content.blogContent,
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    {detailBlog.referance && (
                      <div className="blog_details-widget widget-category">
                        <h4 className="blog_details-widget-title">Referance</h4>
                        <div className="blog_details-widget-category">
                          <p
                            className="paragraphs"
                            dangerouslySetInnerHTML={{
                              __html: detailBlog.referance,
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="blog_details-comment pb-40">
                      <h4 className="blog_details-comment-title">
                        {detailBlog.review.length} Comments:
                      </h4>
                      {detailBlog.review.map((data, index) => (
                        <div className="blog_details-comment-item" key={index}>
                          <div className="blog_details-comment-img">
                            <img src={data.avatar} alt="" />
                          </div>
                          <div className="blog_details-comment-content">
                            <h6>{data.userName}</h6>
                            <span>
                              {moment(detailBlog.createdAt).format("MMM Do YY")}{" "}
                              at{" "}
                              {moment(detailBlog.createdAt).format("hh:MM a")}
                            </span>
                            <p>{data.blogComment}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="blog_details-message">
                    <h3 className="blog_details-message-title">
                      Leave A Comment
                    </h3>
                    <form className="blog_details-form">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="blog_details-form-input mb-30">
                            <input
                              type="text"
                              value={isAuth === true ? loginData.firstname : ""}
                              placeholder="Your Name"
                              disabled={true}
                            />
                            <span className="inner-icon">
                              <i className="fa-thin fa-user" />
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="blog_details-form-input mb-30">
                            <input
                              type="email"
                              value={isAuth === true ? loginData.email : ""}
                              placeholder="Email Address"
                              disabled={true}
                            />
                            <span className="inner-icon">
                              <i className="fa-thin fa-envelope" />
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="blog_details-form-input mb-50 blog_details-form-textarea">
                            <textarea
                              name="message"
                              cols={30}
                              rows={10}
                              placeholder="Write Comment"
                              value={userComment}
                              onChange={(e) => setUserComment(e.target.value)}
                              style={
                                buttonPress === true && userComment === ""
                                  ? {
                                      background: "#ffe6e6",
                                    }
                                  : {}
                              }
                            />
                            <span className="inner-icon">
                              <i className="fa-thin fa-pen" />
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="blog_details-form-submit">
                            <div
                              className="blog_details-form-btn"
                              onClick={(e) => handleSubmit(e)}
                            >
                              <span className="theme-btn blog_details-btn">
                                Post A Comment
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="blog_details-sidebar mb-60">
                  <div className="blog_details-widget">
                    <h4 className="blog_details-widget-title">Categories</h4>
                    <div className="blog_details-widget-tag">
                      <ul>
                        {categoryArray.map((data, index) => (
                          <li key={index}>
                            <Link to={`/blog-category/${data.name}`}>
                              {data.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="blog_details-widget">
                    <h4 className="blog_details-widget-title">Popular Blogs</h4>
                    {blogList.slice(0, 4).map((individualBlog, index) => (
                      <div className="blog_details-widget-course" key={index}>
                        <div className="blog_details-course-img">
                          <Link to={`/blog-info/${individualBlog.slugUrl}`}>
                            <img src={individualBlog.slider[0]} alt />
                          </Link>
                        </div>
                        <div className="blog_details-course-info">
                          <h6>
                            <Link to={`/blog-info/${individualBlog.slugUrl}`}>
                              {individualBlog.blogTitle}
                            </Link>
                          </h6>
                          {/* <Link className="inner-course-rate">Free</Link> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default BlogDetails;
