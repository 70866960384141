import React from "react";
import HomeSlider from "./home/HomeSlider";
import PopularUniversity from "./home/PopularUniversity";
import WhatWeDo from "./home/WhatWeDo";
import Blogs from "./home/Blogs";
import WhyUseOurPlatform from "./home/WhyUseOurPlatform";
import EducationalCounslingMode from "./home/EducationalCounslingMode";
import PracticeTest from "./home/PracticeTest";
import CounterArea from "./home/CounterArea";
import CourseArea from "./home/CourseArea";
import CarrerPage from "./home/CarrerPage";
import OurTeacher from "./home/OurTeacher";
import TestimonialArea from "./home/TestimonialArea";
import OurBlogs from "./home/OurBlogs";

const Home = () => {
  return (
    <>
      <HomeSlider />
      <WhatWeDo />
      <WhyUseOurPlatform />
      <PopularUniversity />
      <br />
      <br />
      <EducationalCounslingMode />
      <PracticeTest />
      <OurBlogs />

      {/* <CounterArea /> */}
      {/* <CourseArea /> */}
      <CarrerPage />
      {/* <OurTeacher /> */}
      <TestimonialArea />
    </>
  );
};

export default Home;
