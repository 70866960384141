import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { Typography, Radio, Col, Button, Space } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import moment from "moment/moment";
import Header from "../../student_registation/components/Header";

const DraftSOP = () => {
  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Generate Draft Personal Statement"
            description="Generate Draft Personal Statement"
          />
        </div>
      </div>
    </>
  );
};

export default DraftSOP;
