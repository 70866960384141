import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { Typography, Radio, Col, Button, Space } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import countries from "../json_datas/countries.json";
import Header from "./Header";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setFinancialSupport,
} from "../../../redux/dashboard/PageRenderSlice";

const FinancialSupport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData } = useSelector((store) => store.Athentication);
  const { countryList } = useSelector((store) => store.DashboardPage);
  const { currentFormStep, studentDetail } = useSelector(
    (store) => store.StudentRegd
  );

  const [availableFunds, setAvailableFunds] = useState(true);
  const [educationLoan, setEducationLoan] = useState(true);
  const [loanApprovment, setLoanApprovement] = useState(true);
  const [sponsorship, setSponsorship] = useState(false);
  const [sponsorName, setSponsorName] = useState("");
  const [country, setCountry] = useState("");
  const [phnNo, setPhnNo] = useState("");
  const [loanPartner, setloanPartner] = useState(true);
  const [buttonPress, setbuttonPress] = useState(false);

  // ErrorMessage
  const [availableFundsError, setAvailableFundsError] = useState(false);
  const [educationLoanError, setEducationLoanError] = useState(false);
  const [loanApprovmentError, setLoanApprovementError] = useState(false);
  const [sponsorshipError, setSponsorshipError] = useState(false);
  const [sponsorNameError, setSponsorNameError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [phnNoError, setPhnNoError] = useState("");
  const [loanPartnerError, setloanPartnerError] = useState(false);

  const [study_budget, setstudy_budget] = useState("20000");
  const [study_budgetError, setstudy_budgetError] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const modelclose = () => {
    setModal(!modal);
    setAvailableFunds(true);
  };

  useEffect(() => {
    if (studentDetail.currentFormStep > 6) {
      setAvailableFunds(studentDetail.financialSupport.availableFunds);
      setEducationLoan(studentDetail.financialSupport.educationLoan);
      setLoanApprovement(studentDetail.financialSupport.loanApprovment);
      setSponsorship(studentDetail.financialSupport.sponsorship);
      setSponsorName(studentDetail.financialSupport.sponsorName);
      setCountry(studentDetail.financialSupport.country);
      setPhnNo(studentDetail.financialSupport.phnNo);
      setloanPartner(studentDetail.financialSupport.loanPartner);
    }
  }, [studentDetail]);

  useEffect(() => {
    if (buttonPress === true) {
      if (sponsorship === true) {
        if (country === "") {
          setCountryError("Required");
        } else {
          setCountryError("");
        }
        if (phnNo === "") {
          setPhnNoError("Required");
        } else {
          setPhnNoError("");
        }
        if (sponsorName === "") {
          setSponsorNameError("Required");
        } else {
          setSponsorNameError("");
        }
      }
    }
  }, [buttonPress, sponsorship, country, phnNo, sponsorName]);

  const handleKeyDown = (e) => {
    // Allow numeric keys, backspace, and arrow keys
    if (
      !/^\d$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    }
  };

  const studentUpdateForm = async () => {
    if (studentDetail.currentFormStep > 6) {
      dispatch(dashPageStatuseChange(7));
    } else {
    const formData = {
      _id: loginData._id,
      financialSupport: {
        availableFunds: availableFunds,
        educationLoan: educationLoan,
        loanApprovment: loanApprovment,
        sponsorship: sponsorship,
        sponsorName: sponsorName,
        country: country,
        phnNo: phnNo,
        loanPartner: loanPartner,
      },
      financialSupportStatus: true,
      currentFormStep: 7,
    };

    const student = await dispatch(updateStudent(formData));
    if (student.payload.success) {
      dispatch(setFinancialSupport(formData));
      dispatch(dashPageStatuseChange(7));
    }
    }
  };

  const financialSupportClick = async () => {
    setbuttonPress(true);

    if (sponsorship === true) {
      if (country === "") {
        setCountryError("Required");
      } else {
        setCountryError("");
      }
      if (phnNo === "") {
        setPhnNoError("Required");
      } else {
        setPhnNoError("");
      }
      if (sponsorName === "") {
        setSponsorNameError("Required");
      } else {
        setSponsorNameError("");
      }
    }

    if (availableFunds === true) {
      studentUpdateForm();
    } else {
      if (educationLoan === true) {
        if (loanApprovment === true) {
          studentUpdateForm();
        } else {
          if (loanPartner === true) {
            studentUpdateForm();
          } else {
            setModal(true);
          }
        }
      } else {
        if (sponsorship === true) {
          studentUpdateForm();
        } else {
          setModal(true);
        }
      }
    }
  };

  const marks = [
    {
      value: 20000,
      label: "20K",
    },
    {
      value: 150000,
      label: "150K",
    },
  ];

  function valuetext(value) {
    setstudy_budget(String(value));
    return `${value}K`;
  }

  const handleChange = (event, newValue) => {
    setstudy_budget(String(newValue));
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Financial Support"
            description="Pursuing higher education in the USA requires good financial support starting from USD 25000 a year. Proof of funds usually includes a letter from the sponsor with a bank statement or a loan sanction letter from the bank or a family bank statement with sufficient funds."
          />
          <div className="inputs_container">
            <div className="radio_div_container">
              <span className="radio_title">
                What is the tuition fee cap you have in mind?
              </span>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Amount"
                type="text"
                id="text"
                className="input_style"
                error={study_budgetError !== "" ? true : false}
                helperText={study_budgetError}
                onChange={(e) =>
                  setstudy_budget(e.target.value.replace(/[^0-9]/g, ""))
                }
                value={study_budget}
                autocomplete="off"
                size="small"
                inputProps={{
                  minLength: 20000,
                  maxLength: 150000,
                }}
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div className="dn_input_box margin_bg">
              <Slider
                aria-label="Always visible"
                defaultValue={Number(study_budget)}
                getAriaValueText={valuetext}
                onChange={handleChange}
                // step={5000}
                value={Number(study_budget)}
                marks={marks}
                valueLabelDisplay="on"
                className="input_style"
                min={20000}
                max={150000}
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Do you have the funds available? (self & family)
              </span>
              <Radio.Group defaultValue={true}>
                <Radio
                  value={true}
                  checked={availableFunds === true}
                  onClick={() => {
                    setAvailableFunds(true);
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={availableFunds === false}
                  onClick={() => {
                    setAvailableFunds(false);
                    setEducationLoan(true);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>

            {availableFunds === false && (
              <>
                <div className="dn_input_box">
                  <div
                    className="input_style"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className="radio_title">
                      Are you planning to apply for an educational loan?
                    </span>

                    <Radio.Group defaultValue={true}>
                      <Radio
                        value={true}
                        checked={educationLoan === true}
                        onClick={() => {
                          setEducationLoan(true);
                          setLoanApprovement(true);
                        }}
                      >
                        Yes
                      </Radio>
                      <Radio
                        value={false}
                        checked={educationLoan === false}
                        onClick={() => {
                          setEducationLoan(false);
                          setSponsorship(false);
                        }}
                      >
                        No
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                {educationLoan === true ? (
                  <>
                    <div
                      className="dn_input_box"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span className="radio_title">
                        Is your loan approved?
                      </span>
                      <Radio.Group defaultValue={true}>
                        <Radio
                          value={true}
                          checked={loanApprovment === true}
                          onClick={() => {
                            setLoanApprovement(true);
                          }}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value={false}
                          checked={loanApprovment === false}
                          onClick={() => {
                            setLoanApprovement(false);
                            setloanPartner(true);
                          }}
                        >
                          No
                        </Radio>
                      </Radio.Group>
                    </div>

                    {loanApprovment === false && (
                      <div className="radio_div_container">
                        <span className="radio_title">
                          Do you want us to share your details to our student
                          loans partner?
                        </span>
                        <Radio.Group defaultValue={true}>
                          <Radio
                            value={true}
                            checked={loanPartner === true}
                            onClick={() => {
                              setloanPartner(true);
                            }}
                          >
                            Yes
                          </Radio>
                          <Radio
                            value={false}
                            checked={loanPartner === false}
                            onClick={() => {
                              setloanPartner(false);
                            }}
                          >
                            No
                          </Radio>
                        </Radio.Group>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="dn_input_box">
                      <div className="input_style"></div>
                    </div>
                    <div className="radio_div_container">
                      <span className="radio_title">
                        Do you have a sponsor for your tuition fees and
                        expenses, either in your home country or in the USA?
                      </span>
                      <Radio.Group defaultValue={false}>
                        <Radio
                          value={true}
                          checked={sponsorship === true}
                          onClick={() => {
                            setSponsorship(true);
                          }}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value={false}
                          checked={sponsorship === false}
                          onClick={() => {
                            setSponsorship(false);
                          }}
                        >
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                    {sponsorship === true && (
                      <>
                        <div className="dn_input_box">
                          <TextField
                            label=" Name of the Sponsor"
                            type="email"
                            id="text"
                            className="input_style"
                            error={sponsorNameError !== "" ? true : false}
                            helperText={sponsorNameError}
                            value={sponsorName}
                            onChange={(e) => setSponsorName(e.target.value)}
                            autocomplete="off"
                            size="small"
                          />
                        </div>
                        <div className="dn_input_box">
                          <TextField
                            id="select"
                            label="Country of birth"
                            select
                            className="input_style"
                            error={countryError !== "" ? true : false}
                            helperText={countryError}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            size="small"
                          >
                            {countries.map((country, index) => (
                              <MenuItem key={index} value={country.name}>
                                {country.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="dn_input_box">
                          <TextField
                            label="Phone Number"
                            type="email"
                            id="text"
                            className="input_style"
                            error={phnNoError !== "" ? true : false}
                            helperText={phnNoError}
                            value={phnNo}
                            onChange={(e) => setPhnNo(e.target.value)}
                            onKeyDown={handleKeyDown}
                            autocomplete="off"
                            size="small"
                          />
                        </div>
                        <div className="dn_input_box">
                          <div className="input_style"></div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <div className="button_container">
              <Button
                type="primary"
                // loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={() => financialSupportClick()}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialSupport;
