import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import Header from "./Header";
import { Typography, Radio, Col, Button, Space } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import moment from "moment/moment";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setAdminssionIntent,
  setEnglishScore,
} from "../../../redux/dashboard/PageRenderSlice";

const English_Test = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData } = useSelector((store) => store.Athentication);
  const { studentDetail } = useSelector((store) => store.StudentRegd);

  const [englishexamType, setEnglishExamType] = useState("");
  const [englishTestDate, setenglishTestDate] = useState("");
  const [englishTestYear, setenglishTestYear] = useState("");
  const [englishTestMonth, setenglishTestMonth] = useState("");
  const [englishTotalScore, setenglishTotalScore] = useState("");
  const [englishTotalScoreConfirm, setenglishTotalScoreConfirm] = useState("");
  const [englishReading, setenglishReading] = useState("");
  const [englishReadingConfirm, setenglishReadingConfirm] = useState("");
  const [englishListening, setenglishListening] = useState("");
  const [englishListeningConfirm, setenglishListeningConfirm] = useState("");
  const [englishSpeaking, setenglishSpeaking] = useState("");
  const [englishSpeakingConfirm, setenglishSpeakingConfirm] = useState("");
  const [englishWriting, setenglishWriting] = useState("");
  const [englishWritingConfirm, setenglishWritingConfirm] = useState("");

  const [englishexamTypeError, setEnglishExamTypeError] = useState("");
  const [englishTotalScoreError, setenglishTotalScoreError] = useState("");
  const [englishReadingError, setenglishReadingError] = useState("");
  const [englishListeningError, setenglishListeningError] = useState("");
  const [englishSpeakingError, setenglishSpeakingError] = useState("");
  const [englishWritingError, setenglishWritingError] = useState("");
  const [englishDateError, setenglishDateError] = useState("");
  //=================== test score =======================
  const [admissionexamtypeTest, setadmissionExamTypeTest] = useState("");
  const [admissionExamName, setadmissionExamName] = useState("");
  const [admissioneScore, setadmissioneScore] = useState("");
  const [admissioneTestYear, setadmissioneTestYear] = useState("");
  const [admissioneTestMonth, setadmissioneTestMonth] = useState("");
  const [admissioneTestDate, setadmissioneTestDate] = useState("");

  const [admissionexamtypeTestError, setadmissionExamTypeTestError] =
    useState("");
  const [admissioneDateOfTestError, setadmissioneDateOfTestError] =
    useState("");
  const [admissioneScoreError, setadmissioneScoreError] = useState("");

  //=================== test score =======================

  const currentDate = new Date();
  const years = [];
  for (let i = 0; i < 18; i++) {
    const year = currentDate.getFullYear();
    years.push(year);
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  const uniqyear = [...new Set(years)];
  const SelectYear = [];
  for (let i = 0; i < uniqyear.length; i++) {
    SelectYear.push({
      id: i + 1,
      name: uniqyear[i],
    });
  }

  const selectMonth = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const selectDate = [];

  for (let i = 1; i <= 31; i++) {
    selectDate.push({
      id: i,
      name: i.toString(),
    });
  }

  const selectTest = [
    {
      id: 1,
      name: "TOEFL",
    },
    {
      id: 2,
      name: "IELTS",
    },
    {
      id: 3,
      name: "PTE",
    },
    {
      id: 4,
      name: "Duolingo",
    },
  ];

  const selectTestChange = (e) => {
    const text = e.target.value;
    setEnglishExamType(text);
    setenglishTotalScore("");
    setenglishTotalScoreError("");
    setenglishTotalScoreConfirm("");
  };

  const selectIELTSBandScore = [
    {
      id: 1,
      name: 5.0,
    },
    {
      id: 2,
      name: 5.5,
    },
    {
      id: 3,
      name: 6.0,
    },
    {
      id: 4,
      name: 6.5,
    },
    {
      id: 5,
      name: 7.0,
    },
    {
      id: 6,
      name: 7.5,
    },
    {
      id: 7,
      name: 8.0,
    },
    {
      id: 8,
      name: 8.5,
    },
    {
      id: 9,
      name: 9.0,
    },
  ];

  const selectIELTSListeningScore = [
    {
      id: 1,
      name: 5.0,
    },
    {
      id: 2,
      name: 5.5,
    },
    {
      id: 3,
      name: 6.0,
    },
    {
      id: 4,
      name: 6.5,
    },
    {
      id: 5,
      name: 7.0,
    },
    {
      id: 6,
      name: 7.5,
    },
    {
      id: 7,
      name: 8.0,
    },
    {
      id: 8,
      name: 8.5,
    },
    {
      id: 9,
      name: 9.0,
    },
  ];

  const selectIELTSReadingScore = [
    {
      id: 1,
      name: 5.0,
    },
    {
      id: 2,
      name: 5.5,
    },
    {
      id: 3,
      name: 6.0,
    },
    {
      id: 4,
      name: 6.5,
    },
    {
      id: 5,
      name: 7.0,
    },
    {
      id: 6,
      name: 7.5,
    },
    {
      id: 7,
      name: 8.0,
    },
    {
      id: 8,
      name: 8.5,
    },
    {
      id: 9,
      name: 9.0,
    },
  ];
  const selectIELTSSpeakingScore = [
    {
      id: 1,
      name: 5.0,
    },
    {
      id: 2,
      name: 5.5,
    },
    {
      id: 3,
      name: 6.0,
    },
    {
      id: 4,
      name: 6.5,
    },
    {
      id: 5,
      name: 7.0,
    },
    {
      id: 6,
      name: 7.5,
    },
    {
      id: 7,
      name: 8.0,
    },
    {
      id: 8,
      name: 8.5,
    },
    {
      id: 9,
      name: 9.0,
    },
  ];

  const selectIELTSWritingScore = [
    {
      id: 1,
      name: 5.0,
    },
    {
      id: 2,
      name: 5.5,
    },
    {
      id: 3,
      name: 6.0,
    },
    {
      id: 4,
      name: 6.5,
    },
    {
      id: 5,
      name: 7.0,
    },
    {
      id: 6,
      name: 7.5,
    },
    {
      id: 7,
      name: 8.0,
    },
    {
      id: 8,
      name: 8.5,
    },
    {
      id: 9,
      name: 9.0,
    },
  ];

  // ==========================test score=============================
  const selectTest1 = [
    {
      id: 1,
      name: "GMAT",
    },
    {
      id: 2,
      name: "GRE",
    },
    {
      id: 3,
      name: "OTHER",
    },
  ];
  const selectTest2 = [
    {
      id: 1,
      name: "SAT",
    },
    {
      id: 2,
      name: "ACT",
    },
    {
      id: 3,
      name: "OTHER",
    },
  ];
  const selectTestChange1 = (e) => {
    const text = e.target.value;
    setadmissionExamTypeTest(text);
  };

  const selectenglishscrore = (e) => {
    let value = "";
    if (englishexamType === "IELTS") {
      const text = e;
      value = text;
      setenglishTotalScore(text);
    } else {
      value = e;
      setenglishTotalScore(value);
    }

    if (englishexamType === "TOEFL") {
      if (Number(value) >= 60 && Number(value) <= 119) {
        setenglishTotalScoreConfirm(value);
        setenglishTotalScoreError("");
      } else {
        setenglishTotalScoreError("English score should be between 60-119");
        setenglishTotalScoreConfirm("");
      }
    } else if (englishexamType === "IELTS") {
      setenglishTotalScoreConfirm(value);
      setenglishTotalScoreError("");
    } else if (englishexamType === "PTE" || englishexamType === "Duolingo") {
      if (Number(value) >= 50 && Number(value) <= 90) {
        setenglishTotalScoreConfirm(value);
        setenglishTotalScoreError("");
      } else {
        setenglishTotalScoreError("English score should be between 50-90");
        setenglishTotalScoreConfirm("");
      }
    } else {
      setenglishTotalScoreConfirm("");
      setenglishTotalScoreError("");
    }
  };
  const selectReadingScroore = (e) => {
    let value = "";
    if (englishexamType === "IELTS") {
      const text = e;
      value = text;
      setenglishReading(text);
    } else {
      value = e;
      setenglishReading(value);
    }

    if (englishexamType === "TOEFL") {
      if (Number(value) >= 15 && Number(value) <= 30) {
        setenglishReadingConfirm(value);
        setenglishReadingError("");
      } else {
        setenglishReadingError("Reading score should be between 15-30");
        setenglishReadingConfirm("");
      }
    } else if (englishexamType === "IELTS") {
      setenglishReadingConfirm(value);
      setenglishReadingError("");
    } else if (englishexamType === "PTE" || englishexamType === "Duolingo") {
      if (Number(value) >= 50 && Number(value) <= 90) {
        setenglishReadingConfirm(value);
        setenglishReadingError("");
      } else {
        setenglishReadingError("Reading score should be between 50-90");
        setenglishReadingConfirm("");
      }
    } else {
      setenglishReadingConfirm("");
      setenglishReadingError("");
    }
  };
  const selectlisteningScroore = (e) => {
    let value = "";
    if (englishexamType === "IELTS") {
      const text = e;
      value = text;
      setenglishListening(text);
    } else {
      value = e;
      setenglishListening(value);
    }

    if (englishexamType === "TOEFL") {
      if (Number(value) >= 15 && Number(value) <= 30) {
        setenglishListeningConfirm(value);
        setenglishListeningError("");
      } else {
        setenglishListeningError("Listening score should be between 15-30");
        setenglishListeningConfirm("");
      }
    } else if (englishexamType === "IELTS") {
      setenglishListeningConfirm(value);
      setenglishListeningError("");
    } else if (englishexamType === "PTE" || englishexamType === "Duolingo") {
      if (Number(value) >= 50 && Number(value) <= 90) {
        setenglishListeningConfirm(value);
        setenglishListeningError("");
      } else {
        setenglishListeningError("Listening score should be between 50-90");
        setenglishListeningConfirm("");
      }
    } else {
      setenglishListeningConfirm("");
      setenglishListeningError("");
    }
  };
  const selectSpekingScroore = (e) => {
    let value = "";
    if (englishexamType === "IELTS") {
      const text = e;
      value = text;
      setenglishSpeaking(text);
    } else {
      value = e;
      setenglishSpeaking(value);
    }

    if (englishexamType === "TOEFL") {
      if (Number(value) >= 15 && Number(value) <= 30) {
        setenglishSpeakingConfirm(value);
        setenglishSpeakingError("");
      } else {
        setenglishSpeakingError("Speaking score should be between 15-30");
        setenglishSpeakingConfirm("");
      }
    } else if (englishexamType === "IELTS") {
      setenglishSpeakingConfirm(value);
      setenglishSpeakingError("");
    } else if (englishexamType === "PTE" || englishexamType === "Duolingo") {
      if (Number(value) >= 50 && Number(value) <= 90) {
        setenglishSpeakingConfirm(value);
        setenglishSpeakingError("");
      } else {
        setenglishSpeakingError("Speaking score should be between 50-90");
        setenglishSpeakingConfirm("");
      }
    } else {
      setenglishSpeakingConfirm("");
      setenglishSpeakingError("");
    }
  };
  const selectWrittingScroore = (e) => {
    let value = "";
    if (englishexamType === "IELTS") {
      const text = e;
      value = text;
      setenglishWriting(text);
    } else {
      value = e;
      setenglishWriting(value);
    }

    if (englishexamType === "TOEFL") {
      if (Number(value) >= 15 && Number(value) <= 29) {
        setenglishWritingConfirm(value);
        setenglishWritingError("");
      } else {
        setenglishWritingError("writing score should be between 15-29");
        setenglishWritingConfirm("");
      }
    } else if (englishexamType === "IELTS") {
      setenglishWritingConfirm(value);
      setenglishWritingError("");
    } else if (englishexamType === "PTE" || englishexamType === "Duolingo") {
      if (Number(value) >= 50 && Number(value) <= 90) {
        setenglishWritingConfirm(value);
        setenglishWritingError("");
      } else {
        setenglishWritingError("writing score should be between 50-90");
        setenglishWritingConfirm("");
      }
    } else {
      setenglishWritingConfirm("");
      setenglishWritingError("");
    }
  };

  const englishScoreClick = async () => {
    if (studentDetail.currentFormStep > 7) {
      dispatch(dashPageStatuseChange(8));
    } else {
      const total_eng_sc =
        Number(englishReadingConfirm) +
        Number(englishListeningConfirm) +
        Number(englishSpeakingConfirm) +
        Number(englishWritingConfirm);
      let service = false;
      if (englishexamType !== "") {
        if (total_eng_sc === Number(englishTotalScoreConfirm)) {
          setenglishTotalScoreError("The total score is incorrect.");
          setenglishReadingError("The total score is incorrect.");
          setenglishListeningError("The total score is incorrect.");
          setenglishSpeakingError("The total score is incorrect.");
          setenglishWritingError("The total score is incorrect.");
        }

        if (englishTotalScoreConfirm === "") {
          setenglishTotalScoreError("Enter scrore");
        }
        if (englishReadingConfirm === "") {
          setenglishReadingError("Enter scrore");
        }
        if (englishListeningConfirm === "") {
          setenglishListeningError("Enter scrore");
        }
        if (englishSpeakingConfirm === "") {
          setenglishSpeakingError("Enter scrore");
        }
        if (englishWritingConfirm === "") {
          setenglishWritingError("Enter scrore");
        }

        if (
          englishTestDate === "" ||
          englishTestYear === "" ||
          englishTestMonth === ""
        ) {
          setenglishDateError("Enter Date/Month/Year");
        }
        if (
          englishTestDate !== "" &&
          englishTestYear !== "" &&
          englishTestMonth !== "" &&
          englishTotalScoreConfirm !== "" &&
          englishReadingConfirm !== "" &&
          englishListeningConfirm !== "" &&
          englishSpeakingConfirm !== "" &&
          englishWritingConfirm !== "" &&
          total_eng_sc === Number(englishTotalScoreConfirm)
        ) {
          service = true;
        }
      } else {
        service = true;
      }
      let admins = false;
      if (admissionexamtypeTest !== "") {
        if (admissioneScore === "") {
          setadmissioneScoreError("Enter scrore");
        }
        if (
          admissioneTestYear === "" ||
          admissioneTestMonth === "" ||
          admissioneTestDate === ""
        ) {
          setadmissioneDateOfTestError("Enter Date/Month/Year");
        }
        if (
          admissioneScore !== "" &&
          admissioneTestYear !== "" &&
          admissioneTestMonth !== "" &&
          admissioneTestDate !== ""
        ) {
          admins = true;
        }
      } else {
        admins = true;
      }
      if (service === true && admins === true) {
        const formData = {
          _id: loginData._id,
          testScore: {
            englishexamType: englishexamType,
            englishTestDate: `${englishTestDate}-${englishTestMonth}-${englishTestYear}`,
            englishTotalScore: englishTotalScoreConfirm,
            englishReading: englishReadingConfirm,
            englishListening: englishListeningConfirm,
            englishSpeaking: englishSpeakingConfirm,
            englishWriting: englishWritingConfirm,
            admissionexamtypeTest: admissionexamtypeTest,
            admissionExamName: admissionexamtypeTest,
            admissioneDateOfTest: `${admissioneTestDate}-${admissioneTestMonth}-${admissioneTestYear}`,
            admissioneScore: admissioneScore,
          },
          currentFormStep: 8,
          testScoreStatus: true,
        };
        const student = await dispatch(updateStudent(formData));
        if (student.payload.success) {
          dispatch(setEnglishScore(formData));
          dispatch(dashPageStatuseChange(8));
        }
      }
    }
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title=" Test Score(s)"
            description=" Please choose the applicable test(s) and input the corresponding
              score(s). Keep in mind that failing to provide test scores may
              lead to limited matching results.Also, the matching process might
              overlook a suitable program if the college/university does not
              recognize the TEST you took."
          />
          <div className="inputs_container">
            <div className="dn_input_box">
              <TextField
                id="select"
                label="English Test Type"
                select
                className="input_style"
                error={englishexamTypeError !== "" ? true : false}
                helperText={englishexamTypeError}
                value={englishexamType}
                onChange={(e) => selectTestChange(e)}
                size="small"
              >
                {selectTest.map((test, index) => (
                  <MenuItem key={index} value={test.name}>
                    {test.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {englishexamType === "" ? (
              <div className="dn_input_box">
                <div className="input_style">
                  <div className="advise_conta">
                    <div className="advise_div">
                      <div className="adv_tit_conta">
                        <span>Advisory</span>
                      </div>
                      <div className="adv_dis_conta">
                        <div className="sing_dis_div">
                          <span className="spa_dis_1">1</span>
                          <span className="spa_dis_2">TOEFL</span>
                          <span className="spa_dis_3">
                            Accepted by all colleges/universities
                          </span>
                        </div>
                        <div className="sing_dis_div">
                          <span className="spa_dis_1">2</span>
                          <span className="spa_dis_2">IELTS</span>
                          <span className="spa_dis_3">
                            Accepted by 3500+ colleges/universities
                          </span>
                        </div>
                        <div className="sing_dis_div">
                          <span className="spa_dis_1">3</span>
                          <span className="spa_dis_2">PTE</span>
                          <span className="spa_dis_3">
                            Accepted by 1200+ colleges/universities
                          </span>
                        </div>
                        <div className="sing_dis_div">
                          <span className="spa_dis_1">4</span>
                          <span className="spa_dis_2">Duolingo</span>
                          <span className="spa_dis_3">
                            Accepted by 100+ colleges/universities
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="dn_input_box">
                <div className="input_style"></div>
              </div>
            )}

            {englishexamType === "TOEFL" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="Total Score (60-119)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishTotalScoreError !== "" ? true : false}
                    helperText={englishTotalScoreError}
                    value={englishTotalScore}
                    onChange={(e) =>
                      selectenglishscrore(e.target.value.replace(/\D/g, ""))
                    }
                    inputProps={{
                      min: 60,
                      max: 119,
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestYear(e.target.value)}
                      value={englishTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestMonth(e.target.value)}
                      value={englishTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestDate(e.target.value)}
                      value={englishTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Reading (15-30)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishReadingError !== "" ? true : false}
                    helperText={englishReadingError}
                    value={englishReading}
                    onChange={(e) =>
                      selectReadingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Listening (15-30)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishListeningError !== "" ? true : false}
                    helperText={englishListeningError}
                    value={englishListening}
                    onChange={(e) =>
                      selectlisteningScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Speaking (15-30)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishSpeakingError !== "" ? true : false}
                    helperText={englishSpeakingError}
                    value={englishSpeaking}
                    onChange={(e) =>
                      selectSpekingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Writing (15-29)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishWritingError !== "" ? true : false}
                    helperText={englishWritingError}
                    value={englishWriting}
                    onChange={(e) =>
                      selectWrittingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
              </>
            )}
            {englishexamType === "IELTS" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Overall Bandscore"
                    select
                    className="input_style"
                    error={englishTotalScoreError !== "" ? true : false}
                    helperText={englishTotalScoreError}
                    value={englishTotalScore}
                    onChange={(e) => selectenglishscrore(e.target.value)}
                    size="small"
                  >
                    {selectIELTSBandScore.map((test, index) => (
                      <MenuItem key={index} value={test.name}>
                        {test.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestYear(e.target.value)}
                      value={englishTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestMonth(e.target.value)}
                      value={englishTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestDate(e.target.value)}
                      value={englishTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Listening"
                    select
                    className="input_style"
                    error={englishListeningError !== "" ? true : false}
                    helperText={englishListeningError}
                    value={englishListening}
                    onChange={(e) => selectlisteningScroore(e.target.value)}
                    size="small"
                  >
                    {selectIELTSListeningScore.map((test, index) => (
                      <MenuItem key={index} value={test.name}>
                        {test.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Reading"
                    select
                    className="input_style"
                    error={englishReadingError !== "" ? true : false}
                    helperText={englishReadingError}
                    value={englishReading}
                    onChange={(e) => selectReadingScroore(e.target.value)}
                    size="small"
                  >
                    {selectIELTSReadingScore.map((test, index) => (
                      <MenuItem key={index} value={test.name}>
                        {test.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Speaking"
                    select
                    className="input_style"
                    error={englishSpeakingError !== "" ? true : false}
                    helperText={englishSpeakingError}
                    value={englishSpeaking}
                    onChange={(e) => selectSpekingScroore(e.target.value)}
                    size="small"
                  >
                    {selectIELTSSpeakingScore.map((test, index) => (
                      <MenuItem key={index} value={test.name}>
                        {test.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Writing"
                    select
                    className="input_style"
                    error={englishWritingError !== "" ? true : false}
                    helperText={englishWritingError}
                    value={englishWriting}
                    onChange={(e) => selectWrittingScroore(e.target.value)}
                    size="small"
                  >
                    {selectIELTSWritingScore.map((test, index) => (
                      <MenuItem key={index} value={test.name}>
                        {test.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            )}

            {englishexamType === "PTE" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label=" Overall Score (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishTotalScoreError !== "" ? true : false}
                    helperText={englishTotalScoreError}
                    value={englishTotalScore}
                    onChange={(e) =>
                      selectenglishscrore(e.target.value.replace(/\D/g, ""))
                    }
                    inputProps={{
                      min: 60,
                      max: 119,
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestYear(e.target.value)}
                      value={englishTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestMonth(e.target.value)}
                      value={englishTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestDate(e.target.value)}
                      value={englishTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Reading (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishReadingError !== "" ? true : false}
                    helperText={englishReadingError}
                    value={englishReading}
                    onChange={(e) =>
                      selectReadingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Listening (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishListeningError !== "" ? true : false}
                    helperText={englishListeningError}
                    value={englishListening}
                    onChange={(e) =>
                      selectlisteningScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Speaking (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishSpeakingError !== "" ? true : false}
                    helperText={englishSpeakingError}
                    value={englishSpeaking}
                    onChange={(e) =>
                      selectSpekingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label=" Writing (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishWritingError !== "" ? true : false}
                    helperText={englishWritingError}
                    value={englishWriting}
                    onChange={(e) =>
                      selectWrittingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
              </>
            )}
            {englishexamType === "Duolingo" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label=" Overall Score (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishTotalScoreError !== "" ? true : false}
                    helperText={englishTotalScoreError}
                    value={englishTotalScore}
                    onChange={(e) =>
                      selectenglishscrore(e.target.value.replace(/\D/g, ""))
                    }
                    inputProps={{
                      min: 60,
                      max: 119,
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestYear(e.target.value)}
                      value={englishTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestMonth(e.target.value)}
                      value={englishTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={englishDateError !== "" ? true : false}
                      helperText={englishDateError}
                      onChange={(e) => setenglishTestDate(e.target.value)}
                      value={englishTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Reading (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishReadingError !== "" ? true : false}
                    helperText={englishReadingError}
                    value={englishReading}
                    onChange={(e) =>
                      selectReadingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Listening (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishListeningError !== "" ? true : false}
                    helperText={englishListeningError}
                    value={englishListening}
                    onChange={(e) =>
                      selectlisteningScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Speaking (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishSpeakingError !== "" ? true : false}
                    helperText={englishSpeakingError}
                    value={englishSpeaking}
                    onChange={(e) =>
                      selectSpekingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label=" Writing (50-90)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={englishWritingError !== "" ? true : false}
                    helperText={englishWritingError}
                    value={englishWriting}
                    onChange={(e) =>
                      selectWrittingScroore(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
              </>
            )}
          </div>

          <div className="inputs_container">
            {loginData.degreeName === "Undergraduate" ? (
              <>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Admission Test Type"
                    select
                    className="input_style"
                    value={admissionexamtypeTest}
                    onChange={(e) => selectTestChange1(e)}
                    size="small"
                  >
                    {selectTest2.map((test, index) => (
                      <MenuItem key={index} value={test.name}>
                        {test.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            ) : (
              <>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Admission Test Type"
                    select
                    className="input_style"
                    value={admissionexamtypeTest}
                    onChange={(e) => selectTestChange1(e)}
                    size="small"
                  >
                    {selectTest1.map((test, index) => (
                      <MenuItem key={index} value={test.name}>
                        {test.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            )}
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            {admissionexamtypeTest === "GMAT" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="Total Score (200-800)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={admissioneScoreError !== "" ? true : false}
                    helperText={admissioneScoreError}
                    value={admissioneScore}
                    onChange={(e) => setadmissioneScore(e.target.value)}
                    inputProps={{
                      min: 200,
                      max: 800,
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestYear(e.target.value)}
                      value={admissioneTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestMonth(e.target.value)}
                      value={admissioneTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestDate(e.target.value)}
                      value={admissioneTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </>
            )}
            {admissionexamtypeTest === "GRE" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="General Test Score (230-340)"
                    type="text"
                    id="text"
                    className="input_style"
                    error={admissioneScoreError !== "" ? true : false}
                    helperText={admissioneScoreError}
                    value={admissioneScore}
                    onChange={(e) => setadmissioneScore(e.target.value)}
                    inputProps={{
                      min: 230,
                      max: 340,
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestYear(e.target.value)}
                      value={admissioneTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestMonth(e.target.value)}
                      value={admissioneTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestDate(e.target.value)}
                      value={admissioneTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </>
            )}
            {admissionexamtypeTest === "SAT" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="Overall Score"
                    type="text"
                    id="text"
                    className="input_style"
                    error={admissioneScoreError !== "" ? true : false}
                    helperText={admissioneScoreError}
                    value={admissioneScore}
                    onChange={(e) => setadmissioneScore(e.target.value)}
                    inputProps={{
                      min: 400,
                      max: 1600,
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestYear(e.target.value)}
                      value={admissioneTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestMonth(e.target.value)}
                      value={admissioneTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestDate(e.target.value)}
                      value={admissioneTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </>
            )}
            {admissionexamtypeTest === "ACT" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="Composite"
                    type="text"
                    id="text"
                    className="input_style"
                    error={admissioneScoreError !== "" ? true : false}
                    helperText={admissioneScoreError}
                    value={admissioneScore}
                    onChange={(e) => setadmissioneScore(e.target.value)}
                    inputProps={{
                      min: 12,
                      max: 36,
                    }}
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestYear(e.target.value)}
                      value={admissioneTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestMonth(e.target.value)}
                      value={admissioneTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestDate(e.target.value)}
                      value={admissioneTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </>
            )}
            {admissionexamtypeTest === "OTHER" && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="Total Score"
                    type="text"
                    id="text"
                    className="input_style"
                    error={admissioneScoreError !== "" ? true : false}
                    helperText={admissioneScoreError}
                    value={admissioneScore}
                    onChange={(e) => setadmissioneScore(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestYear(e.target.value)}
                      value={admissioneTestYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestMonth(e.target.value)}
                      value={admissioneTestMonth}
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={admissioneDateOfTestError !== "" ? true : false}
                      helperText={admissioneDateOfTestError}
                      onChange={(e) => setadmissioneTestDate(e.target.value)}
                      value={admissioneTestDate}
                      size="small"
                    >
                      {selectDate.map((date, index) => (
                        <MenuItem key={index} value={date.name}>
                          {date.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="  Name of the Test"
                    type="text"
                    id="text"
                    className="input_style"
                    value={admissionExamName}
                    onChange={(e) => setadmissionExamName(e.target.value)}
                    autocomplete="off"
                    size="small"
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </div>
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
              </>
            )}
            <div className="button_container">
              <Button
                type="primary"
                // loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={(e) => englishScoreClick(e)}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default English_Test;
