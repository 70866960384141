import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  countryList: [],
  personalInfo: {},
  internationalTravel: {},
  admissionIntent: {},
  educationalEvaluation: {},
  FinancialSupport: {},
  EnglishScore: {},
  UgEnglishScore: {},
  otherNSecurity: {},
  otherObjectivs: {},
  matchPreferences: {},
  pageRenderStatus: 0,
  isAllcountryLoading: false,
};

export const getAllCountry = createAsyncThunk(
  "Country/getAllCountry",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/country/getcountrylist`;
      const resp = await axios(url);
      return resp.data.countryList;
    } catch (error) {
      return thunkAPI.rejectWithValue("Country Not Found");
    }
  }
);

const PageRenderSlice = createSlice({
  name: "DashboardPage",
  initialState,
  reducers: {
    setPersonalInformation(state, action) {
      state.personalInfo = action.payload;
    },
    setInternationalTravel(state, action) {
      state.internationalTravel = action.payload;
    },
    setAdminssionIntent(state, action) {
      state.admissionIntent = action.payload;
    },
    setEducationalEvaluation(state, action) {
      state.educationalEvaluation = action.payload;
    },
    setWorkExperience(state, action) {
      state.WorkExperience = action.payload;
    },
    setFinancialSupport(state, action) {
      state.FinancialSupport = action.payload;
    },
    setEnglishScore(state, action) {
      state.EnglishScore = action.payload;
    },
    setUgEnglishScore(state, action) {
      state.UgEnglishScore = action.payload;
    },
    setOtherNSecurity(state, action) {
      state.otherNSecurity = action.payload;
    },
    setOtherObjectives(state, action) {
      state.otherObjectivs = action.payload
    },
    setMatchPreferences(state, action) {
      state.matchPreferences = action.payload
    },
    dashPageStatuseChange(state, action) {
      state.pageRenderStatus = action.payload;
    },
    removeallStudentFormDara(state, action) {
      state.pageRenderStatus = 0;
      state.personalInfo = {};
      state.internationalTravel = {};
      state.admissionIntent = {};
      state.educationalEvaluation = {};
      state.WorkExperience = {};
      state.FinancialSupport = {};
      state.EnglishScore = {};
      state.UgEnglishScore = {};
      state.otherNSecurity = {};
      state.otherObjectivs = {};
      state.matchPreferences = {}
    },



  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllCountry.pending, (state) => {
        state.isAllcountryLoading = true;
      })
      .addCase(getAllCountry.fulfilled, (state, action) => {
        state.countryList = action.payload;
        state.isAllcountryLoading = false;
        localStorage.setItem("countryList", JSON.stringify(state.countryList));
      })
      .addCase(getAllCountry.rejected, (state) => {
        state.isAllcountryLoading = true;
      });
  },
});

export const {
  setPersonalInformation,
  setInternationalTravel,
  setAdminssionIntent,
  setEducationalEvaluation,
  setWorkExperience,
  setFinancialSupport,
  setUgEnglishScore,
  setEnglishScore,
  setOtherNSecurity,
  setOtherObjectives,
  dashPageStatuseChange,
  removeallStudentFormDara
} = PageRenderSlice.actions;
export default PageRenderSlice.reducer;
